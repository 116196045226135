/* eslint-disable max-lines */
import { FC, useEffect, useState } from 'react';
import {
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import DocumentService, { UploadDoc } from 'notarize-now/src/service/document';
import DocViewer from './DocViewer';
import { LoadingScreen } from '@enotarylog/molecules/LoadingScreen';
import { getAcceptedDocs } from 'notarize-now/src/utils/getAcceptedDocs';
import { CONFIRM_BTN } from 'notarize-now/src/constants';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';

export interface RightPannelProps {
  uploadedDocumentDialogOpen: boolean;
  setDocs: (docList: UploadDoc[]) => void;
  docs: UploadDoc[];
  editFileIndex: number;
  addFiles: (fileList: UploadDoc[], invalid: boolean) => void;
  setEditFileIndex: (index: number) => void;
  setUploadedDocumentDialogOpen: (open: boolean) => void;
  setFileCompletedStateByIndex: (value: boolean, updateIndex: number) => void;
  selectedDoc: any;
  setSelectedDoc?(updatedDoc: any);
  selectedIndex: number;
  setSelectedIndex(index: number);
  setIsEdit(isEdit: boolean): void;
  isEdit: boolean;
  setHasChanged(hasChanged: boolean): void;
  onSubmit?(updatedDoc: UploadDoc);
}

const useStyles = makeStyles(() => ({
  confirmDocumentBtn: {
    color: CONFIRM_BTN,
    position: 'absolute',
    bottom: '0',
    right: '0',
    marginRight: '60px',
    marginBottom: '10px',
  },
}));


const RightPannel: FC<RightPannelProps> = ({
  setDocs,
  docs,
  editFileIndex,
  addFiles,
  setFileCompletedStateByIndex,
  setUploadedDocumentDialogOpen,
  selectedDoc,
  setSelectedDoc,
  selectedIndex,
  setEditFileIndex,
  onSubmit,
  setHasChanged
}) => {
  const { primaryColor, secondaryColor } = useCustomColors();
  const classes = useStyles();
  // define trackers for split document prompt
  const [displayExtractPagesPrompt, setDisplayExtractPagesPrompt ] = useState(false);
  // forces the loading ux to ensure a document correctly loads in open dialog
  const [forceDocViewLoadingView, setForceDocViewLoadingView] = useState(false);

  const loadDocumentInOpenDialog = (selectedFileIndex: number) => {
    setForceDocViewLoadingView(true);
    setEditFileIndex(selectedFileIndex);
    setTimeout(() => { setForceDocViewLoadingView(false); }, 500);
  };

  useEffect(() => {
    loadDocumentInOpenDialog(selectedIndex);
    setSelectedDoc(docs[editFileIndex]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editFileIndex, selectedDoc, selectedIndex]);

  // set the selected file as completed, close dialog, and unselect file
  const confirmSelectedDocument = () => {
    setFileCompletedStateByIndex(true, editFileIndex);
    setHasChanged(false);
    onSubmit({
      ...docs[editFileIndex],
      completed: true,
    });
    (getAcceptedDocs(docs).length === docs.length) && setUploadedDocumentDialogOpen(false);
  };

  const confirmDocumentBtn = () =>
    <Button
      color='primary'
      style={{ backgroundColor: primaryColor }}
      variant='contained'
      disabled={selectedDoc && selectedDoc.file.title === ''}
      className={classes.confirmDocumentBtn}
      onClick={() => { confirmSelectedDocument();
        setDocs(docs);
      }}
    >
      Confirm
    </Button>
  // allow file received from child components such as DocViewer
  const onBlobFileAdded = (fileBlob: Blob) => {
    const docRec = DocumentService.convertBlobToRecord(fileBlob);
    addFiles([ docRec ], false);
  };

  return (
    <>
      {
        !forceDocViewLoadingView && editFileIndex > -1  ?
          <DocViewer
            height='100%'
            onAddFile={onBlobFileAdded}
            setDisplayExtractPagesPrompt={setDisplayExtractPagesPrompt}
            displayExtractPagesPrompt={displayExtractPagesPrompt}
            viewDoc={selectedDoc}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          /> :
          <LoadingScreen />
      }
      { confirmDocumentBtn() }
    </>
  );
}
export default RightPannel;
