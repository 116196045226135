import {
  Box,
  Button,
  Grid,
  Divider,
  CardContent,
  CardHeader,
  IconButton,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './use-styles';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import getAField from './get-a-field';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';

interface AddDialogProps {
  dialogTitle: string,
  isOpenState: boolean,
  setOpenState: (val: boolean) => void,
  iniValues: any,
  valSchema: any,
  submitBtnCopy: string,
  textCopy: string,
  textFieldList: {
    name: string,
    label: string,
    required: boolean,
    dataTestId: string,
  }[],
  customFieldList: any[],
  handleOnSubmit: (values: any) => void,
  className?: string,
};

const AddDialog: React.FC<AddDialogProps> = ({
  dialogTitle,
  isOpenState,
  setOpenState,
  iniValues,
  valSchema,
  submitBtnCopy,
  textCopy,
  textFieldList,
  customFieldList,
  handleOnSubmit,
  className,
}: AddDialogProps) => {
  const { primaryColor, secondaryColor } = useCustomColors();
  const useCustomStyles = makeStyles(() => ({
    textField: {
      color: '507F91',
      '& .Mui-focused': {
        color: secondaryColor,
      },
      '& .Mui-focused.MuiFilledInput-underline:after': {
        borderColor: secondaryColor,
      },
    },
    phoneLabel: {
      '& .MuiInputLabel-root.Mui-focused': {
        color: secondaryColor,
      },
      '& .Mui-focused.MuiFilledInput-underline:after': {
        borderColor: secondaryColor,
      },
    },
  }));
  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <Dialog
      open={isOpenState}
      maxWidth='sm'
    >
      <DialogTitle
        className={classes.titleText}
      >
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <CardHeader
            titleTypographyProps={{ variant: 'h4' }}
            title={dialogTitle}
            className={classes.header}
          />
          <IconButton
            aria-label='close'
            data-testid='close-modal'
            onClick={() => setOpenState(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>

        <Formik
          enableReinitialize
          initialValues={iniValues}
          validationSchema={valSchema}
          onSubmit={(values) => { handleOnSubmit(values)}}
        >
          {({
            errors,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            handleSubmit,
            handleChange,
            touched,
            values,
          }) => (
            <form
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
            >

              <Container maxWidth={false}>
                <CardContent>
                  <Box
                    alignItems='center'
                    display='flex'
                    justifyContent='center'
                  >
                    <p
                      className={classes.titleBanner}
                    >
                      { textCopy }
                    </p>
                  </Box>

                  <Box
                    alignItems='center'
                    display='flex'
                    justifyContent='center'
                  >

                    <Grid
                      container
                      spacing={3}
                    >
                      {
                        textFieldList.map((
                          fieldDetails: any,
                          index: number
                        ) => getAField({
                          classes,
                          customClasses,
                          fieldDetails,
                          handleBlur,
                          handleChange,
                          values,
                          touched,
                          errors,
                          index
                        }))
                      }

                      {
                        customFieldList.map((makeCustomTag, index) =>
                          <Grid
                            item
                            md={11}
                            xs={12}
                            key={index}
                            className={classes.textFieldContainer}
                          >
                            { makeCustomTag({
                              setFieldValue,
                              handleBlur,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }) }
                          </Grid>
                        )
                      }
                    </Grid>
                  </Box>
                </CardContent>
              </Container>
              <Divider />
              <CardContent>
                <Box
                  mt={2}
                  alignContent='center'
                  textAlign='center'
                >
                  <Button
                    variant='contained'
                    type='submit'
                    data-testid='submit-button'
                    className={classes.btn}
                    style={{
                      backgroundColor: primaryColor || "#ED5F42",
                      color: '#FFFFFF',
                    }}
                  >
                    { submitBtnCopy }
                  </Button>
                </Box>
              </CardContent>
            </form>
          ) }
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddDialog;
