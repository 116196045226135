import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import { AppThunk, RootState } from "@enotarylog/redux/store";
import { Role } from "@enotarylog/core/types/role";
import { IndexedById } from "@enotarylog/core/types/paginated";


interface RolesState {
  roles: IndexedById<Role>;
  loading: boolean;
  orgsBeingFetched: Record<string, boolean>; // Key is organizationId, value tells you if that organization is currently being fetched. Good for avoiding double-fetching
};

export const initialState: RolesState = {
  roles: {
    byId: {},
    allIds: [],
    total: 0
  },
  loading: false,
  orgsBeingFetched: {},
};

export const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setLoading: (state: RolesState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setRoles: (state: RolesState, action: PayloadAction<{ total: number, roles: Array<Role> }>) => {
      const { roles, total } = action.payload;

      const curr = (state.roles || {
        byId: {},
        allIds: [],
        total: 0
      }) as IndexedById<Role>;

      const newById = _.chain(roles)
        .groupBy('id')
        .mapValues((el) => _.head(el))
        .value();

      curr.byId = {
        ...curr.byId,
        ...newById
      }

      curr.allIds = _.keys(curr.byId);
      curr.total = total;

      state.roles.byId = curr.byId;
      state.roles.allIds = curr.allIds;
      state.roles.total = curr.total;

    },
  }
});




/**
 * Gets list of organizations in enl enterprise system
 */
export const getRoles = (): AppThunk => async (dispatch, __, { axios }) => {
  dispatch(slice.actions.setLoading(true))
  const { data } = await axios.get('/api/roles');

  dispatch(slice.actions.setRoles({
    total: data.length,
    roles: data,
  }))
  dispatch(slice.actions.setLoading(false))
}










export const { setRoles } = slice.actions;


export const reducer = slice.reducer;
export const selector = (state: RootState) => state.roles
export default slice;