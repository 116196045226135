import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  emailGrid: {
    padding: '30px 100px 0px',
    backgroundColor: '#FFFFFF',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: '1px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  emailGridCnt: {
    paddingTop: '30px',
    paddingBottom: '30px'
  },
  emailSessionGrid: {
    paddingBottom: '30px'
  },
}));

export default useStyles;