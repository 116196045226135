import React, {
  useState,
  useEffect,
} from 'react';
import {
  Link,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Grid,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { UploadDoc } from './../../../../service/document';
import useStyles from './use-styles';
import TransactionSummaryDialog from './TransactionSummaryDialog';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';

interface TransactionSummaryProps {
  documentList: UploadDoc[];
  signerList: any[];
  setActiveNextButton: (value: boolean) => void;
};

const TransactionSummary: React.FC<TransactionSummaryProps> = ({
  setActiveNextButton,
  documentList,
  signerList,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [infoGridHead, setInfoGridHead] = useState([]);
  const [infoGridData, setInfoGridData] = useState([]);

  useEffect(() => {
    setActiveNextButton(true)
  }, []);
  const signersCost = 8;
  const witnessesCost = 5;
  const documentCost = 5;
  const estimatedNotaryRoomCost = 12;
  // const mockSignerData = [{
  //   'firstName': 'firstName',
  //   'lastName': 'lastName',
  //   'type': 'Signer',
  //   'email': 'email@enotary.com',
  // },{
  //   'firstName': 'firstName',
  //   'lastName': 'lastName',
  //   'type': 'Witness',
  //   'email': 'email@enotary.com',
  // },{
  //   'firstName': 'firstName',
  //   'lastName': 'lastName',
  //   'type': 'Primary Signer',
  //   'email': 'email@enotary.com',
  // }];
  // const mockDocumentData = [{
  //   file: {
  //     title: 'document file title'
  //   }
  // },{
  //   file: {
  //     title: 'document file title'
  //   }
  // }];
  const getParticipantsCost = () => {
    let totalCost = 0;
    signerList?.filter((signer) => (signer.type === 'Signer' || signer.type === 'Primary Signer')).map(() => totalCost += signersCost);
    signerList?.filter((signer) => (signer.type !== 'Signer' && signer.type !== 'Primary Signer')).map(() => totalCost += witnessesCost);
    return totalCost;
  };
  const getParticipantsCostList = () => {
    const finalDataList = [];
    signerList?.filter((signer) => {
      const rowData = [];
      rowData.push(`${signer.firstName} ${signer.lastName}`);
      rowData.push(signer.type ? signer.type : 'Signer');
      rowData.push(signer.email);
      if(signer.type === 'Signer' || signer.type === 'Primary Signer') rowData.push(`$${signersCost}`);
      else rowData.push(`$${witnessesCost}`);
      finalDataList.push(rowData);
      return false;
    });
    return finalDataList;
  };
  const getDocumentsCostList = () => {
    const finalDataList = [];
    documentList?.filter((document) => {
      const rowData = [];
      rowData.push(document?.file?.title);
      rowData.push(`$${documentCost}`);
      finalDataList.push(rowData);
      return false;
    });
    return finalDataList;
  };
  const estimatedParticipantsCost = getParticipantsCost();
  const estimatedDocumentsCost = documentCost * (documentList.length ? documentList.length : 1);
  const estimatedTotalCost = estimatedNotaryRoomCost + estimatedParticipantsCost + estimatedDocumentsCost;
  const participantsCostList = getParticipantsCostList();
  const documentsCostList = getDocumentsCostList();
  const { primaryColor, secondaryColor } = useCustomColors();

  return(
    <Grid
      container
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        item
        md={6}
        xs={12}
      >
        <TransactionSummaryDialog
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          tableHeaders={infoGridHead}
          tableData={infoGridData}
        />
        <TableContainer
          component={Paper}
          className={classes.summaryGridCnt}
        >
          <Table
            aria-label='TransactionSummary table'
          >
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  <Typography
                    color='secondary'
                    style={{ color: secondaryColor }}
                    className={classes.titleText}
                  >
                    Description
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography
                    color='secondary'
                    style={{ color: secondaryColor }}
                    className={classes.titleText}
                  >
                    Quantity
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography
                    color='secondary'
                    style={{ color: secondaryColor }}
                    className={classes.titleText}
                  >
                    Estimated Cost
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align='center'><Typography className={classes.subTitleText}>Notary Room</Typography></TableCell>
                <TableCell align='center'>1</TableCell>
                <TableCell align='center'>
                  $
                  {estimatedNotaryRoomCost}
                  .00
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='center'>
                  <Typography className={classes.subTitleText}># of participants</Typography>
                  <Typography
                    color='secondary'
                    style={{ color: secondaryColor }}
                    variant='subtitle2'
                    className={classes.subTitleHelperText}
                    onClick={() => {
                      setInfoGridHead(['Participants', 'Participant Type', 'Email', 'Cost']);
                      setInfoGridData(participantsCostList);
                      setIsOpen(true);
                    }}
                  >
                    See More
                  </Typography>
                </TableCell>
                <TableCell align='center'>{signerList.length}</TableCell>
                <TableCell align='center'>
                  $
                  {estimatedParticipantsCost}
                  .00
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align='center'
                  className={classes.tableFooterCell}
                >
                  <Typography className={classes.subTitleText}># of documents</Typography>
                  <Typography
                    color='secondary'
                    style={{ color: secondaryColor }}
                    variant='subtitle2'
                    className={classes.subTitleHelperText}
                    onClick={() => {
                      setInfoGridHead(['Documents', 'Cost']);
                      setInfoGridData(documentsCostList);
                      setIsOpen(true);
                    }}
                  >
                    See More
                  </Typography>
                </TableCell>
                <TableCell
                  align='center'
                  className={classes.tableFooterCell}
                >
                  {documentList.length}
                </TableCell>
                <TableCell
                  align='center'
                  className={classes.tableFooterCell}
                >
                  $
                  { estimatedDocumentsCost }
                  .00
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='center'><Typography className={classes.tableFooterText}>Session Total*</Typography></TableCell>
                <TableCell align='center'></TableCell>
                <TableCell align='center'>
                  <Typography className={classes.tableFooterText}>
                    $
                    {estimatedTotalCost}
                    .00
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          align='center'
          className={classes.detailText}
        >
          *This estimate is an approximation based on information provided. Actual cost may change based on the total number of notarizations per document.
        </Typography>
        <Typography
          align='center'
          className={clsx(classes.detailText, classes.textUnderline)}
          style={{ paddingBottom: 0 }}
        >
          Payment will be collected at the end of the notarization and will be responsible by the Primary Signer.
        </Typography>
        <Typography
          align='center'
          className={classes.detailText}
          style={{ paddingTop: 0 }}
        >
          For a more accurate total click
          {' '}
          <Link
            className={classes.textUnderline}
            style={{ color: primaryColor }}
          >
            here
          </Link>
        </Typography>
      </Grid>
    </Grid>
  )
};

export default TransactionSummary;
