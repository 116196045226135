import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';



export const initializeAxios = (config?: AxiosRequestConfig) => {
  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    if (_.isEmpty(token) || _.isNil(token)) {
      return config;
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: (!_.isNil(token) && token !== 'null') ? `Bearer ${token}` : config.headers.Authorization
      }
    };
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error(error);
      return Promise.reject((error.response && error.response.data) || error.message)
    }
  );

  delete axiosInstance.defaults.adapter;
  return axiosInstance;

};


const axiosInstance = initializeAxios();
(window as any).axios = axiosInstance;


export default axiosInstance;
