import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Formik, FormikTouched } from 'formik';
import {
  Box,
  Button,
  Grid,
  TextField,
  Divider,
  CardContent,
  CardHeader,
  makeStyles,
  IconButton,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiPhoneNumber from 'material-ui-phone-number';
import addPrimarySignerSchema from '../../../../utils/validation/primarySignerSchema';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';

export interface AddPrimarySignerProps {
  setActiveNextButton: (value: boolean) => void;
  setShowNextButton: (value: boolean) => void;
  onSubmit: (values: any) => void;
  handleNext: () => void;
  showPrompt: boolean;
  setShowPrompt: (val: boolean) => void;
  className?: string;
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiCardContent-root': {
      paddingTop: '0px'
    },
    '& .MuiCardHeader-root .MuiCardHeader-content, .MuiTypography-root .MuiCardHeader-title .MuiTypography-h4': {
      fontWeight: 600,
      fontSize: '2.6rem'
    },
  },
  textFieldContainer: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  titleText: {
    paddingBottom: '0px',
  },
  titleBanner: {
    textAlign: 'center',
    padding: '0px 30px'
  },
}));

interface AddSignerFormFields {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

const AddPrimarySigner: React.FC<AddPrimarySignerProps> = ({
  setShowNextButton,
  setActiveNextButton,
  onSubmit,
  handleNext,
  showPrompt,
  setShowPrompt,
  className,
}) => {

  const { primaryColor, secondaryColor } = useCustomColors();

  const textfieldClasses = makeStyles(() => ({
    fieldLabel: {
      '& .Mui-focused': {
        color: secondaryColor,
      },
      '& .Mui-focused.MuiFilledInput-underline:after': {
        borderColor: secondaryColor,
      }
    },
    phoneLabel: {
      '& .MuiInputLabel-root.Mui-focused': {
        color: secondaryColor,
      },
      '& .Mui-focused.MuiFilledInput-underline:after': {
        borderColor: secondaryColor,
      },
    },
  }));

  const theme = useTheme();

  const handleOnSuccess = () => {
    setShowNextButton(true);
    setActiveNextButton(true);
    handleNext();
  };

  const handleOnSubmit = (primarySigner: any) => {
    onSubmit(primarySigner);
    handleOnSuccess();
  }

  const classes = useStyles();
  const customClasses = textfieldClasses();

  const addPrimaryStartValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  };

  const [addDialogOpen, setAddDialogOpen] = useState(showPrompt);
  useEffect(() => {
    setAddDialogOpen(showPrompt);
  }, [showPrompt])

  type validFieldNames = keyof AddSignerFormFields;

  const getAField = ({
    name,
    label,
    required,
    dataTestId,
    handleBlur,
    handleChange,
    values,
    touched,
    errors,
    index
  }: {
    name: validFieldNames,
    label: string,
    required: boolean,
    dataTestId: string,
    handleBlur: (e: React.FocusEvent<any>) => void,
    handleChange: (e: React.ChangeEvent<any>) => void,
    values: AddSignerFormFields,
    touched: FormikTouched<AddSignerFormFields>,
    errors: any,
    index: number
  }) =>
    <Grid
      item
      md={11}
      xs={12}
      className={classes.textFieldContainer}
      key={index}
    >
      <TextField
        fullWidth
        required={required}
        variant='filled'
        name={name}
        label={label}
        data-testid={dataTestId}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[name]}
        error={Boolean(touched[name] && errors[name])}
        helperText={touched[name] && errors[name]}
        color='secondary'
        className={customClasses.fieldLabel}
      />
    </Grid>


  const addDialog = () => (
    <Dialog
      open={addDialogOpen}
      maxWidth='sm'
    >
      <DialogTitle
        className={classes.titleText}
      >
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Container
            style={{
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <CardHeader
              className={classes.titleText}
              titleTypographyProps={{ variant: 'h4' }}
              title='Primary Signer'
            />
          </Container>
          <IconButton
            aria-label='close'
            data-testid='close-modal'
            onClick={() => setShowPrompt(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>

        <Formik
          enableReinitialize
          initialValues={addPrimaryStartValues}
          validationSchema={addPrimarySignerSchema}
          onSubmit={(values: any) => { handleOnSubmit(values) }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            //isSubmitting,
            touched,
            values,
          }) => (
            <form
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
            >
              <Container maxWidth={false}>
                <CardContent>
                  <Box
                    alignItems='center'
                    display='flex'
                    justifyContent='center'
                  >
                    <p
                      className={classes.titleBanner}
                    >
                      You will receive a link to the email provided after submitting this session on the Summary page.
                    </p>
                  </Box>

                  <Box
                    alignItems='center'
                    display='flex'
                    justifyContent='center'
                  >

                    <Grid
                      container
                      spacing={3}
                    >
                      {
                        [
                          {
                            name: 'firstName' as validFieldNames,
                            label: 'First name',
                            required: true,
                            dataTestId: 'first-name-field',
                          },
                          {
                            name: 'middleName' as validFieldNames,
                            label: 'Middle name',
                            required: false,
                            dataTestId: 'middle-name-field'
                          },
                          {
                            name: 'lastName' as validFieldNames,
                            label: 'Last name',
                            required: true,
                            dataTestId: 'last-name-field'
                          },
                          {
                            name: 'email' as validFieldNames,
                            label: 'Email',
                            required: true,
                            dataTestId: 'email-field'
                          },
                        ].map((
                          aField: {
                            name: validFieldNames,
                            required: boolean,
                            label: string;
                            dataTestId: string;
                          },
                          index: number
                        ) => getAField({
                          name: aField.name,
                          required: aField.required,
                          label: aField.label,
                          dataTestId: aField.dataTestId,
                          handleBlur,
                          handleChange,
                          values,
                          touched,
                          errors,
                          index
                        }))
                      }
                      <Grid
                        item
                        md={11}
                        xs={12}
                        className={classes.textFieldContainer}
                      >
                        <MuiPhoneNumber
                          fullWidth
                          required
                          type='phone'
                          name='phoneNumber'
                          placeholder='Phone'
                          onBlur={(event) => {
                            event.target.value.length <= 2 &&
                              setFieldTouched('phoneNumber', true);
                            values.phoneNumber.length > 2 && handleBlur(event);
                          }}
                          onChange={(e) => {
                            setFieldValue('phoneNumber', e);
                          }}
                          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          value={values.phoneNumber}
                          variant='filled'
                          defaultCountry='us'
                          countryCodeEditable={false}
                          disableAreaCodes
                          label='Phone number'
                          data-testid='phone-number-field'
                          color='secondary'
                          className={customClasses.phoneLabel}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Container>

              <Divider />
              <CardContent>
                <Box
                  mt={2}
                  alignContent='center'
                  textAlign='center'
                >
                  <Button
                    variant='contained'
                    type='submit'
                    data-testid='submit-button'
                    style={{
                      color: '#FFFFFF',
                      backgroundColor: primaryColor || theme.palette.primary.main,
                    }}
                  //disabled={isSubmitting}
                  >
                    ADD SIGNER
                  </Button>
                </Box>
              </CardContent>
            </form>
          )}
        </Formik>

      </DialogContent>

    </Dialog>
  );

  return addDialog();
};

export default AddPrimarySigner;
