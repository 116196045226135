import FormData from 'form-data';
import { v4 as generateId } from 'uuid';
import {
  DOCUMENT_UPLOAD_URL,
  DOCUMENT_DELETE_URL,
  getNotarizationDocUploadWithIdUrl,
} from './urls';
import { AxiosInstance } from 'axios';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { DELETE_NNOW_DOC_MUTATION } from './../../../../libs/gql/src/nnow-document';
import { object } from 'prop-types';
import _ from 'lodash';

export type UploadDoc = {
  file: {
    localFile: any;
    filename: string;
    title: string;
    size?: number;
  };
  completed: boolean;
  id: string;
  docId: string;
  gcsRefId: string;
  status: string;
  serverMessage: string;
};

type UploadedDocument = {
  docId: string;
  gcsRefId: string;
};
export type InvalidDoc = {
  isValid: boolean;
  name?: string;
};

export type DocumentUploadResponse = UploadedDocument[];

export const validExtensions = {
  // doc: true,  // disabled until PDFTron server is updated
  pdf: true,
  png: true,
  // tiff: true, // disabled until PDFTron server is updated
  jpeg: true,
  docx: true,
};

class DocumentService {
  static applyUploadResult(
    uploadDoc: UploadDoc,
    documentList: UploadDoc[],
    response: DocumentUploadResponse | any
  ) {
    const uploadedDetails = response.data[0];
    return documentList.map((aDoc: UploadDoc) =>
      aDoc.id !== uploadDoc.id
        ? aDoc
        : {
          ...aDoc,
          status: 'saved',
          serverMessage: '',
          docId: uploadedDetails.docId,
          gcsRefId: uploadedDetails.gcsRefId,
        }
    );
  }

  // this method is used to push all uploads at once since sessionId is not known until after files added
  static async uploadAllDocuments(
    axios: AxiosInstance,
    documentList: UploadDoc[],
    sessionId: string
  ) {
    const url = getNotarizationDocUploadWithIdUrl(sessionId);
    const formData = new FormData();
    for (const uploadDoc of documentList) {
      formData.append(
        'files',
        uploadDoc.file.localFile,
        uploadDoc.file.filename
      );
    }
    const result = await axios.post(url, formData);
    return result.data?.gcsFileIds
      ? result.data?.gcsFileIds.map((aFile) => aFile.gcsRefId)
      : [];
  }

  // this method was used for uploads triggered as files are added
  static async attemptNextUpload(
    axios: AxiosInstance,
    documentList: UploadDoc[],
    setDocumentList: (list: UploadDoc[]) => void
  ) {
    if (!this.isUploadInProgress(documentList)) {
      const pendingUpload = this.getPendingFile(documentList);
      if (pendingUpload) {
        setDocumentList(
          this.applyFileUploadingStatus(pendingUpload, documentList)
        );
        this.uploadDocument(
          axios,
          pendingUpload,
          (response: DocumentUploadResponse) => {
            setDocumentList(
              this.applyUploadResult(pendingUpload, documentList, response)
            );
          },
          (error: Error) => {
            setDocumentList(
              this.applyUploadError(pendingUpload, error, documentList)
            );
          }
        );
      }
    }
  }

  static isUploadInProgress(documentList: UploadDoc[]) {
    return !!documentList.find(
      (aDoc: UploadDoc) => aDoc.status === 'uploading'
    );
  }

  static applyUploadError(
    uploadDoc: UploadDoc,
    error: Error,
    documentList: UploadDoc[]
  ) {
    return documentList.map((aDoc: UploadDoc) =>
      aDoc.id !== uploadDoc.id
        ? aDoc
        : {
          ...aDoc,
          status: 'error',
          serverMessage: error.message ? error.message : 'Upload Error',
        }
    );
  }

  static applyFileUploadingStatus(
    uploadDoc: UploadDoc,
    documentList: UploadDoc[]
  ) {
    return documentList.map((aDoc: UploadDoc) =>
      aDoc.id !== uploadDoc.id
        ? aDoc
        : {
          ...aDoc,
          status: 'uploading',
        }
    );
  }

  static deleteFile(
    //axios: AxiosInstance,
    apollo: ApolloClient<object>,
    delIndex: number,
    editFileIndex: number,
    documentList: UploadDoc[]
  ) {
    const nextIndex =
      editFileIndex === -1
        ? editFileIndex
        : delIndex === editFileIndex
          ? -1
          : delIndex > editFileIndex
            ? editFileIndex
            : editFileIndex - 1;
    // if needed, purge from server
    const delFile = documentList[delIndex];
    if (delFile && delFile.status === 'saved') {
      //DocumentService.deleteDocument(axios, delFile);
      DocumentService.deleteDocument(apollo, delFile);
    }
    const nextFileList = documentList.filter(
      (item: UploadDoc, index: number) => index !== delIndex
    );
    return {
      nextIndex,
      nextFileList,
    };
  }

  static getPendingFile(documentList: UploadDoc[]): UploadDoc | undefined {
    return documentList.find((aFile: UploadDoc) => aFile.status === 'pending');
  }

  static convertBlobToRecord(aFile: Blob) {
    const title = `GeneratedFile-${new Date().toISOString()}`;
    return {
      file: {
        localFile: aFile,
        filename: `${title}.pdf`,
        title,
      },
      completed: false,
      id: this.generateDocumentId(),
      docId: '',
      gcsRefId: '',
      status: 'pending',
      serverMessage: '',
    };
  }

  static convertFileToRecord(aFile: any) {
    return {
      file: {
        localFile: aFile,
        filename: aFile.name,
        title: aFile.name,
        size: aFile.size,
      },
      completed: false,
      id: this.generateDocumentId(),
      docId: '',
      gcsRefId: '',
      status: 'pending',
      serverMessage: '',
    };
  }

  static handleAddFilesEvent(event: any): {
    fileList: UploadDoc[];
    invalidFiles: boolean | InvalidDoc;
  } {
    const isDropEvent = event.dataTransfer && event.dataTransfer.files;
    const files = isDropEvent ? event.dataTransfer.files : event.target.files;
    const fileArry = this.captureFilesFromEvent(files);
    const invalidFiles =
      _.find(fileArry, (file) => file.isValid === false) || false;
    const fileList = fileArry.filter((file) => file !== invalidFiles);

    if (isDropEvent && files.length > 0) {
      event.dataTransfer.clearData();
    }

    return {
      fileList,
      invalidFiles,
    };
  }

  static captureFilesFromEvent(eventFiles: any) {
    const fileList = [];
    for (let count = 0; count < eventFiles.length; count++) {
      const file = eventFiles.item(count);
      const doc = this.convertFileToRecord(file);
      const checkedRecords = this.checkRecordForError(doc);

      checkedRecords.isValid === true
        ? fileList.push(doc)
        : fileList.push(checkedRecords);

    }
    return fileList;
  }

  static generateDocumentId() {
    return generateId();
  }

  // gql version
  static async deleteDocument(apollo: ApolloClient<object>, file: UploadDoc) {
    try {
      const { data } = await apollo.query({
        query: DELETE_NNOW_DOC_MUTATION,
        variables: {
          gcsRefId: file.gcsRefId,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  // http version
  /*
  static async deleteDocument(
    axios: AxiosInstance,
    file: UploadDoc
  )
  {
    try
    {
      const deleteUrl = `${DOCUMENT_DELETE_URL}${encodeURIComponent(file.gcsRefId)}`;
      await axios.delete(deleteUrl);
    }
    catch(error)
    {
      console.error(error);
    }
  }
  */

  static getFileExtension(filename: string) {
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1].toLowerCase();
    } else {
      return '';
    }
  }

  static isValidExtension(ext: string): boolean {
    return ext in validExtensions;
  }

  static isValidFileSize(file: number): boolean {
    const fileSize = (file / (1024 * 1024)).toFixed(4);

    return Number(fileSize) <= 60 ? true : false;
  }

  static checkRecordForError(doc: UploadDoc): InvalidDoc {
    const recordProperties = {
      validExtensions: this.isValidExtension(
        this.getFileExtension(doc.file.filename)
      ),
      validFileSize: this.isValidFileSize(doc.file.size),
    };

    const invalidProperty = Object.keys(recordProperties).find(
      (key) => recordProperties[key] === false
    );

    return invalidProperty
      ? { isValid: false, name: invalidProperty }
      : { isValid: true };

  }

  static async uploadDocument(
    axios: AxiosInstance,
    uploadDoc: UploadDoc,
    onSuccess: (response: any) => void,
    onError: (error: any) => void
  ) {
    try {
      const formData = new FormData();
      formData.append(
        'files',
        uploadDoc.file.localFile,
        uploadDoc.file.filename
      );
      const result = await axios.post(DOCUMENT_UPLOAD_URL, formData);
      onSuccess(result);
    } catch (error) {
      onError(error);
    }
  }
}

export default DocumentService;
