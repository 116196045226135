import { StepConnector, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const CustomStepConnector = withStyles((theme: Theme) => ({
  vertical: {
    marginLeft: 19,
    padding: 0,
  },
  line: {
    borderColor: theme.palette.divider
  }
}))(StepConnector);

export default CustomStepConnector;
