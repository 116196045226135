import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import { AppThunk, RootState } from "@enotarylog/redux/store";
import { IndexedById } from "@enotarylog/core/types/paginated";

interface Client {
  name: string;
  client_id: string;
  description: string;
}

interface ClientsState {
  clients: IndexedById<Client>;
  loading: boolean;
};

export const initialState: ClientsState = {
  clients: {
    byId: {},
    allIds: [],
    total: 0
  },
  loading: false,
};

export const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setLoading: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setClients: (state: ClientsState, action: PayloadAction<{ total: number, clients: Array<Client> }>) => {
      const { clients, total } = action.payload;

      const curr = (state.clients || {
        byId: {},
        allIds: [],
        total: 0
      }) as IndexedById<Client>;

      const newById = _.chain(clients)
        .groupBy('client_id')
        .mapValues((el) => _.head(el))
        .value();

      curr.byId = {
        ...curr.byId,
        ...newById
      }

      curr.allIds = _.keys(curr.byId);
      curr.total = total;

      state.clients.byId = curr.byId;
      state.clients.allIds = curr.allIds;
      state.clients.total = curr.total;

    },
  }
});




/**
 * Gets list of organizations in enl enterprise system
 */
export const getClients = (): AppThunk => async (dispatch, __, { axios }) => {
  dispatch(slice.actions.setLoading(true))
  const { data } = await axios.get('/api/clients');

  dispatch(slice.actions.setClients({
    total: data.length,
    clients: data,
  }))
  dispatch(slice.actions.setLoading(false))
}










export const { setClients } = slice.actions;


export const reducer = slice.reducer;
export const selector = (state: RootState) => state.clients
export default slice;