import { createSlice } from '@reduxjs/toolkit';
import type { Notary, User } from '@enotarylog/core/types/user';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppThunk, RootState } from '@enotarylog/redux/store';

const parseUser = (user) => {
  if (!user) {
    return user;
  }
  //strip empty strings out of
  // replace namespaced auth0 claims keys
  return _.chain({
    auth0Id: user.sub,
    avatar: user.picture,
    email: user.email,
    name: user.name,
    ...user
  })
    .mapKeys((val, key: string) => _.camelCase(key.replace(/.*\.com\//, '')))
    .mapValues((val, key) => {
      if (key === 'sub') {
        return _.slice(val, _.lastIndexOf(val, '|') + 1).join('')
      }

      return val;
    })
    .value() as User;
}

const parseNotary = (notary) => {
  if (!notary) {
    return notary;
  }
  return _.chain({ ...notary }).mapKeys((_val, key: string) => _.camelCase(key)).value()
}

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  accessToken: string | null,
  user: User | null;
  permissions: string[];
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  accessToken: null,
  user: null,
  permissions: [],
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialize(state: AuthState, action: PayloadAction<{ isAuthenticated: boolean, accessToken: string | null, user: User | null }>) {
      state.isInitialised = true;
      state.isAuthenticated = action.payload.isAuthenticated;

      localStorage.setItem('token', action.payload.accessToken);
      state.accessToken = action.payload.accessToken || state.accessToken;
      state.permissions = action.payload.user?.permissions || [];

      state.user = action.payload.user || state.user;
    },
    login(state: AuthState, action: PayloadAction<{ accessToken: string, user: User }>) {
      state.isAuthenticated = !!action.payload.user;

      localStorage.setItem('token', action.payload.accessToken);
      state.accessToken = action.payload.accessToken;

      state.user = action.payload.user;
      state.permissions = action.payload.user.permissions || [];
    },
    logout(state: AuthState) {
      localStorage.removeItem('token')

      state.permissions = [];
      state.isAuthenticated = false;
      state.isInitialised = false;
      state.user = null;
      state.accessToken = null;
    },
    updateUser(state: AuthState, action: PayloadAction<{ user: User }>) {
      //Dont store null/undefined
      const updatedUser = { ...state.user, ..._.omitBy(action.payload.user, _.isNil) }
      state.user = updatedUser;
    },
    updateNotary(state: AuthState, action: PayloadAction<{ notary: Notary }>) {
      state.user.notary = { ...state.user?.notary, ...action.payload.notary };
    },
    updatePermissions(state: AuthState, action: PayloadAction<{ permissions: Array<string> }>) {
      const updatedPermissions = action.payload.permissions;
      state.permissions = updatedPermissions;
    },
  }
});

export const reducer = slice.reducer;



export const initialize = (isAuthenticated, accessToken, user): AppThunk => async (dispatch, __, { axios, firebase }) => {

  if (isAuthenticated && !_.isNil(accessToken)) {
    try {
      const { data } = await axios.get<{ token: string }>('/api/auth', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      await firebase.auth().signInWithCustomToken(data.token)
    } catch (error) {
      console.log('failed to get fbase token', error)
    }
  }


  return dispatch(slice.actions.initialize({ isAuthenticated, accessToken, user: parseUser(user) }))
}

export const login = (accessToken: string, user: Partial<User>): AppThunk => async (dispatch, __, { axios }) => dispatch(slice.actions.login({ accessToken, user: parseUser(user) }))

export const logout = () => slice.actions.logout()

export const updateUser = (user: User) => slice.actions.updateUser({ user: parseUser(user) })

export const updatePermissions = (permissions: Array<string>) => slice.actions.updatePermissions({ permissions });

export const updateNotary = (notary) => slice.actions.updateNotary({ notary: parseNotary(notary) });


export const selector = (state: RootState) => state.auth;


export default slice;
