import React, { useEffect } from 'react';
import {
  Paper,
  TableContainer,
  Grid,
  Typography,
} from '@material-ui/core';
import useStyles from './use-styles';

interface VerifyEmailProps {
  setShowNextButton: (value: boolean) => void;
  setShowBackButton: (value: boolean) => void;
  sessionId: string;
};

const VerifyEmail: React.FC<VerifyEmailProps> = ({
  setShowNextButton,
  setShowBackButton,
  sessionId
}) => {
  useEffect(() => {
    setShowNextButton(false)
    setShowBackButton(false)
  }, [setShowBackButton, setShowNextButton]);
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        item
        md={11}
        xs={12}
      >
        <TableContainer
          component={Paper}
          className={classes.emailGrid}
        >
          <Typography
            align='center'
            style={{ marginBottom: '10px' }}
          >
            Your session is awaiting verification.
          </Typography>
          <Typography
            align='center'
          >
            All participants have been sent an email from
            {' '}
            <b> noreply@enotarylog.com </b>
            which contains the link and their unique PIN for the session. If you do not see an email please check your spam folder.
          </Typography>
          <Typography
            className={classes.emailGridCnt}
            align='center'
          >
            <u>All participants must be present for the session to start.</u>
          </Typography>
          <Typography
            className={classes.emailSessionGrid}
            align='center'
          >
            Session ID: 
            {' '}
            { sessionId }
          </Typography>
        </TableContainer>
      </Grid>
    </Grid>
  )
};

export default VerifyEmail;