import React from 'react';
import AddDialog from './add-dialog';
import { FormikTouched } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
import useStyles from './use-styles';
import textFieldList from './text-field-list';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';
import { makeStyles } from '@material-ui/core';

interface AddSignerDialogProps {
  isOpenState: boolean;
  setOpenState: (visible: boolean) => void;
  iniValues: any;
  valSchema: any;
  handleOnSubmit: (values: any) => void;
  className?: string;
};

const AddSignerDialog: React.FC<AddSignerDialogProps> = ({
  isOpenState,
  setOpenState,
  iniValues,
  valSchema,
  handleOnSubmit,
  className,
}: AddSignerDialogProps) => {

  const { primaryColor, secondaryColor } = useCustomColors();
  const useCustomStyles = makeStyles(() => ({
    phoneLabel: {
      '& .MuiInputLabel-root.Mui-focused': {
        color: secondaryColor,
      },
      '& .Mui-focused.MuiFilledInput-underline:after': {
        borderColor: secondaryColor,
      },
    },
  }));
  const customClasses = useCustomStyles();

  const customFieldList = [
    ({
      setFieldValue,
      setFieldTouched,
      handleBlur,
      touched,
      errors,
      values
    }: {
      setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
      handleBlur: (e: any) => void,
      setFieldTouched:(e: any,value?: boolean) => void,
      touched: FormikTouched<any>,
      errors: any,
      values: any
    }) =>
      <MuiPhoneNumber
        fullWidth
        required
        type='phone'
        name='phoneNumber'
        onBlur={(event) => {
          event.target.value.length <= 2 &&
            setFieldTouched('phoneNumber', true);
          values.phoneNumber.length > 2 && handleBlur(event);
        }}
        onChange={(newValue: string) => { setFieldValue('phoneNumber', newValue) }}
        helperText={touched.phoneNumber && errors.phoneNumber}
        value={values.phoneNumber}
        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
        variant='filled'
        defaultCountry='us'
        countryCodeEditable={false}
        disableAreaCodes
        label='Phone number'
        data-testid='phone-number-field'
        color='secondary'
        className={customClasses.phoneLabel}
      />
  ];
  return (
    <AddDialog
      dialogTitle='Signer'
      isOpenState={isOpenState}
      setOpenState={setOpenState}
      iniValues={iniValues}
      valSchema={valSchema}
      className={className}
      submitBtnCopy='ADD SIGNER'
      textCopy='Once the session has been submitted, the signer will be sent a link & unique PIN to the email listed below.'
      textFieldList={textFieldList}
      customFieldList={customFieldList}
      handleOnSubmit={handleOnSubmit}
    />
  );
};

export default AddSignerDialog;
