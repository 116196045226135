import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { AxiosInstance } from 'axios';
import firebase from 'firebase';

export const initStore = ({ enableDevTools = true, extraArguments = {} } = {}) => configureStore({
  reducer: rootReducer,
  devTools: enableDevTools,
  middleware: [
    thunk.withExtraArgument(extraArguments)
  ]
})

export type Store = ReturnType<typeof initStore>;
export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch'];
export type AppThunk = ThunkAction<void, RootState, {
  firebase: typeof firebase,
  axios: AxiosInstance,
  apollo?: ApolloClient<NormalizedCacheObject>
}, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
