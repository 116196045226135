import React, { useEffect } from 'react';
import {
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Theme,
  Button,
  DialogActions,
  DialogContentText,
  Typography
} from '@material-ui/core';
import { BACKGROUND } from 'notarize-now/src/constants';
  
interface CloseConfirmationProps {
  setOpenDialog:(openDialog: boolean) => void;
  openDialog: boolean;
  onCloseConfirmation: () => void;
}
  
const useStyles = makeStyles((theme: Theme) => ({
  dialog:{
    width: '660px'
  },
  dialogTitle: {
    padding: 0,
    backgroundColor: BACKGROUND,
  },
  dialogTitleContent:{
    fontSize: '0.9rem',
    fontWeight: 600,
    padding: '20px 20px 20px 50px'
  },
  dialogDescription:{
    paddingLeft: '50px'
  },
  taggingCnt: {
    height: '90vh',
    '& .scrollbar-container': {
      height: 'calc(100% - 440px)',
    },
  },
  tableCnt: {
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& .MuiTableCell-root': {
      padding: theme.spacing(1),
      fontSize: '.75rem',
    }
  },
}));
  
const CloseConfirmationDialog: React.FC<CloseConfirmationProps> = ({ openDialog, setOpenDialog, onCloseConfirmation }) => {
  const classes = useStyles();
  useEffect(() => {
    setOpenDialog(true);
  },[setOpenDialog]);
  
  return (
    <Dialog
      open={openDialog}
      fullWidth={true}
      maxWidth='sm'
    //   className={classes.dialog}
    >
      <DialogTitle
        disableTypography  
        className={classes.dialogTitle}
      >
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Typography className={classes.dialogTitleContent}>Leave this page?</Typography> 
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogDescription}>
        <DialogContentText
          color='textPrimary'
        >
          Warning! You are about to navigate away from document upload. 
          <br/>
          Any documents that are unsaved will need to be uploaded again.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button
          onClick={async () => {
            setOpenDialog(false);
            onCloseConfirmation();
          }}
          color='primary'
          variant='contained'
          autoFocus
        >
          YES, LEAVE
        </Button>
        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
          color='primary'
          variant='contained'
          autoFocus
        >
          NO, CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}
    
  
export default CloseConfirmationDialog;
  