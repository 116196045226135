import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  summaryGridCnt: {
    backgroundColor: '#FFFFFF',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: '1px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  titleText: {
    fontWeight: 600,
    fontSize: 14,
  },
  subTitleText: {
    fontSize: 14,
    fontWeight: 500,
  },
  subTitleHelperText: {
    fontSize: 12,
    fontWeight: 500,
    cursor: 'pointer',
  },
  tableFooterCell: {
    borderColor: '#000000',
  },
  tableFooterText: {
    fontSize: 14,
    fontWeight: 600,
  },
  detailText: {
    fontWeight: 600,
    fontSize: '8px',
    padding: '8px 0',
  },
  textUnderline: {
    textDecoration: 'underline'
  }
}));

export default useStyles;