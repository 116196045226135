import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '@enotarylog/redux/store'
import type { Notification } from '@enotarylog/core/types/vendor/notification';

interface NotificationsState {
  notifications: Notification[];
};

const initialState: NotificationsState = {
  notifications: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state: NotificationsState, action: PayloadAction<{ notifications: Notification[]; }>) {
      const { notifications } = action.payload;

      state.notifications = notifications;
    }
  }
});

export const reducer = slice.reducer;

export const getNotifications = (notifications?: Notification[]): AppThunk => async (dispatch, __, { axios }) => {
  if (notifications) {
    return dispatch(slice.actions.getNotifications({ notifications }));
  }

  const response = await axios.get<Notification[]>('/api/notifications');
  return dispatch(slice.actions.getNotifications({ notifications: response.data }));
};

export default slice;
