import React from 'react';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import {
  UploadDoc,
} from './../../../../service/document';
import license from '../../../../../../../libs/pdftron-webviewer/src/license';


export interface DocViewerProps {
  height?: string;
  children?: React.ReactNode,
  viewDoc: UploadDoc,
  onAddFile: (file: Blob) => void,
  displayExtractPagesPrompt: boolean
  setDisplayExtractPagesPrompt: (val: boolean) => void,
  primaryColor?: string | undefined;
  secondaryColor?: string | undefined;
}

export interface DocViewerState {
  splitPageString: string;
  splitPageStringIsValid: boolean;
  loadedDocIsMultiPage: boolean;
}

class DocViewer extends React.Component<DocViewerProps> {
  viewerRef: React.RefObject<HTMLDivElement>;
  instance?: any;

  state = {
    splitPageString: '',
    splitPageStringIsValid: false,
    loadedDocIsMultiPage: false,
  };

  constructor(props: DocViewerProps)
  {
    super(props);
    this.viewerRef = React.createRef();
  }

  extractPagesPromptClosed()
  {
    return (
      <section>
        <button
          style={{
            backgroundColor: '#356E80',
            color: '#FFFFFF',
            cursor: 'pointer',
          }}
          onClick={(event: any) => {
            this.props.setDisplayExtractPagesPrompt(true);
          }}
        >
          Split Document
        </button>
      </section>
    );
  }

  extractPagesPromptOpen()
  {
    return(
      <>
        <section>
          <input
            placeholder='Comma separated pages'
            type='text'
            value={this.state.splitPageString}
            onChange={(event: any) => {
              const value = event.target.value;
              const nextState = {
                ...this.state,
                splitPageString: value,
                splitPageStringIsValid: this.isValidSplitDocString(value),
              };
              this.setState(nextState);
            }}
          />
          <button
            disabled={! this.state.splitPageStringIsValid}
            style={{
              backgroundColor: '#356E80',
              color: '#FFFFFF',
              cursor: 'pointer',
            }}
            onClick={(event: any) => {
              const extractPages = this.parseSplitDocString(this.state.splitPageString);
              this.extractPages(extractPages);
              this.props.setDisplayExtractPagesPrompt(false);
            }}
          >
            Split Pages
          </button>
          <button
            style={{
              backgroundColor: '#356E80',
              color: '#FFFFFF',
              cursor: 'pointer',
            }}
            onClick={(event: any) => {
              this.props.setDisplayExtractPagesPrompt(false);
            }}
          >
            Cancel
          </button>
        </section>
        {
          this.state.splitPageString !== '' && ! this.state.splitPageStringIsValid ?
            <section>
              Specify the page numbers to extract separated by commas
            </section> : ''
        }
      </>
    );
  }

  parseSplitDocString(value: string): number[]
  {
    const parts = value === '' ? [] : value.split(',');
    const pages = parts
      .map((aPart) => aPart.trim())
      .map((trimPart) => parseInt(trimPart))
      .filter((aNumb) => !isNaN(aNumb));
    return pages;
  }

  isValidSplitDocString(value: string): boolean
  {
    const pages = this.parseSplitDocString(value);
    return pages.length > 0 ? true : false;
  }

  extractPagesPrompt()
  {
    return this.props.displayExtractPagesPrompt ? this.extractPagesPromptOpen() :
      this.extractPagesPromptClosed();
  }

  componentWillReceiveProps()
  {
    if(this.instance)
    {
      this.loadDocument(this.props.viewDoc);
    }
  }

  getInitConfig()
  {
    return {
      custom: {
        disableFeatures: [
          'TextSelection',
          'NotesPanel',
          'FilePicker',
          'Redaction',
          'Download',
          'Print',
          'ThumbnailReordering',
        ],
        disableTools: [
          'AnnotationCreatePolygon',
          'AnnotationCreateTextHighlight',
          'AnnotationCreateTextUnderline',
        ],
        disableElements: [
          'contextMenuPopup',
          'fontSizeSlider',
          'searchButton',
          'opacitySlider',
          'strokeThicknessSlider',
          'stickyToolButton',
          'freeHandToolGroupButton',
          'menuButton',
          'miscToolGroupButton',
          'searchButton',
          'textToolGroupButton',
          'viewControlsButton',
          'linkButton',
          'shapeToolGroupButton',
          'eraserToolButton',
          'thumbnailControl',
          'toolStylePopup',
          'documentControl',
          'bookmarksPanelButton',
          'bookmarksPanel',
          'outlinesPanelButton',
          'outlinesPanel',
          'stickyToolButton',
          'signatureToolButton',
          'thumbnailsPanelButton',
          'leftPanelTabs',
          'opacitySlider',
          'strokeThicknessSlider',
          'documentControl',

        ],
      }
    };
  }

  applyColorToIFrameClass(instance: WebViewerInstance, className: string, color: string)
  {
    const iframeDoc = instance.iframeWindow.document;
    const icons = iframeDoc.querySelectorAll(className);
    icons.forEach((icon: any) => {
      icon.style.color = color;
    });
  }

  applyHideToIFrameClass(instance: WebViewerInstance, className: string)
  {
    const iframeDoc = instance.iframeWindow.document;
    const elements = iframeDoc.querySelectorAll(className);
    elements.forEach((el: any) => {
      el.style.display = 'none';
    });
  }

  applyStyles(instance: WebViewerInstance)
  {
    const uxClasses = [
      '.OverlayText',
      '.Icon',
    ]
    const color = this.props.secondaryColor || '#356E80';
    uxClasses.forEach((aClass: string) => {
      this.applyColorToIFrameClass(instance, aClass, color);
    });

    this.applyHideToIFrameClass(instance, '.divider');

    instance.disableElements([
      'searchButton',
      'toolsButton',
      'menuButton',
      'viewControlsButton',
      'freeHandToolGroupButton',
      'textToolGroupButton',
      'shapeToolGroupButton',
      'eraserToolButton',
      'signatureToolButton',
      'freeTextToolButton',
      'stickyToolButton',
      'miscToolGroupButton',
      'selectToolButton',
    ]);
  }

  async extractPages(pageNumberList: number[])
  {
    if(this.instance)
    {
      const { docViewer } = this.instance;
      const doc = docViewer.getDocument();
      const extractData = await doc.extractPages(pageNumberList);
      const encodeData = new Uint8Array(extractData);
      const blob = new Blob([encodeData], { type: 'application/pdf' });
      this.props.onAddFile(blob);
    }

  }

  loadDocument(viewDoc: UploadDoc)
  {

    const { docViewer } = this.instance;

    docViewer.on('documentLoaded', async () => {
      const doc = docViewer.getDocument();
      const totalPages = doc.getPageCount();
      const loadedDocIsMultiPage = totalPages > 1;
      const nextState = {
        ...this.state,
        loadedDocIsMultiPage
      };
      this.setState(nextState);
    });

    this.instance.loadDocument(
      viewDoc.file.localFile,
      { filename: viewDoc.file.filename }
    );

  }

  async init()
  {
    if(this.viewerRef.current)
    {
      const config = this.getInitConfig();
      const iniParm = {
        ...config,
        path: '/assets/webviewer',
        // throwing CORS errors and breaking multi page previews
        //pdftronServer: 'https://webviewer-server.sandbox.notary.sh',
        l: process.env.PDFTRON_LICENSE || license,
        custom: JSON.stringify({
          ...config.custom
        })
      };
      WebViewer(iniParm, this.viewerRef.current)
        .then((instance) => {
          this.instance = instance;
          this.applyStyles(instance);
          this.loadDocument(this.props.viewDoc);

        })
        .catch((error) => {
          console.error(error);
        });
    }
    else
    {
      console.error('unable to load file');
    }
  }

  async componentDidMount()
  {
    await this.init();
  }

  render()
  {
    return (
      <>
        {
          // disabled split pages prompt for now
          /*
          this.state.loadedDocIsMultiPage ? this.extractPagesPrompt() : ''
          */
        }

        <div
          style={{
            height: this.props.height || '100%',
            visibility: 'visible',
          }}
          className='docViewerContainer'
          ref={this.viewerRef}
        />
      </>
    );
  }
}

export default DocViewer;
