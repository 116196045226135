import { object } from 'yup';
import nameBaseSchema from './core/nameBaseSchema';
import phoneSchema from './core/phoneSchema';
import emailSchema from './core/emailSchema';

// bind and export schema
const addPrimarySignerSchema = object().shape({
  firstName: nameBaseSchema.required('First name is required'),
  middleName: nameBaseSchema.nullable(),
  lastName: nameBaseSchema.required('Last name is required'),
  email: emailSchema.required('Email is required'),
  phoneNumber: phoneSchema.required('Phone number is required'),
});
export default addPrimarySignerSchema;