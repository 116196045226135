import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CssBaseline } from '@material-ui/core';
import { ApolloProvider } from '@apollo/client';
import { initApollo } from './lib/apollo';
import { Provider } from 'react-redux';
import { initialize } from 'launchdarkly-js-client-sdk';
import { initStore } from '@enotarylog/redux/store';

(async () => {
  const client = initialize(process.env.NX_LAUNCH_DARKLY_API_KEY, {
    key: 'anonymous'
  });
  await client.waitForInitialization();
  const { domains = [] } = client.variation('lucky-orange', {});

  const host = window.location.host;
  const found = domains.find((el) => host.includes(el));

  if (found) {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", '/lucky-orange.js');
    head.appendChild(script);

  }
})();
const apollo = (window as unknown as any).client = initApollo();
const store = (window as unknown as any).store = initStore({
  extraArguments: { apollo }
});


ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <ApolloProvider client={apollo}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
