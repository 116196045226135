import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CardHeader,
  makeStyles,
  Theme,
  IconButton
} from '@material-ui/core';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
import { UploadDoc } from 'notarize-now/src/service/document';
import LeftPannelNav from './LeftPannelNav';
import RightPannel from './RightPannel';
import { findIndexOfFirstIncompleteDoc } from 'notarize-now/src/utils/findFirstIncompleteDoc';
import DeleteConfirmationDialog from './DeleteConfirmation';
import CloseConfirmationDialog from './CloseConfirmation';
import { BACKGROUND } from 'notarize-now/src/constants';

export interface UploadDocumentProps {
  docs: UploadDoc[];
  participants?: any;
  setDocs(docs: UploadDoc[]): void;
  onClose?(): void;
  onSubmit?(): void;
  typePreset?: string;
  initialSelectedIndex?: number// id instead?
  editFileIndex: number;
  validDocFormats: string;
  deleteFile: (delIndex: number) => void;
  addFiles: (fileList: UploadDoc[], invalid: boolean) => void;
  onFilesAdded: (event: Event | any) => void;
  setEditFileIndex: (index: number) => void;
  setUploadedDocumentDialogOpen: (open: boolean) => void;
  uploadedDocumentDialogOpen: boolean;
  setFileCompletedStateByIndex: (value: boolean, updateIndex: number) => void;
  isEdit: boolean;
  setIsEdit(isEdit: boolean): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    //marginTop: '200px',
    //maxHeight : 'calc(100vh - 200px)'
  },
  dialogTitle: {
    padding: 0,
    backgroundColor: BACKGROUND

  },
  taggingCnt: {
    height: '100%',
    '& .scrollbar-container': {
      height: 'calc(100% - 440px)',
    },
  },
  tableCnt: {
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& .MuiTableCell-root': {
      padding: theme.spacing(1),
      fontSize: '.75rem',
    }
  },
}));

const UploadedDocumentDialog: React.FC<UploadDocumentProps> = ({ docs, setDocs, initialSelectedIndex, addFiles, deleteFile, editFileIndex, onFilesAdded, setEditFileIndex, setFileCompletedStateByIndex, setUploadedDocumentDialogOpen, validDocFormats, uploadedDocumentDialogOpen, setIsEdit, isEdit }) => {
  const classes = useStyles();
  const [hasChanged, setHasChanged] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(initialSelectedIndex);
  const [nextQueuedDocIndex, setNextQueuedDocIndex] = useState<number>(-1);
  const cloneSelectedDoc = docs[initialSelectedIndex];
  const [selectedDoc, setSelectedDoc] = useState(cloneSelectedDoc);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
  const [deleteDocIndex, setDeleteDocIndex] = useState(-1);

  useEffect(() => {
    if (selectedIndex !== -1) {
      const cloneSelectedDoc = docs[selectedIndex];
      setSelectedDoc(cloneSelectedDoc);
    } else {
      const editSelectedDoc = docs[editFileIndex];
      setSelectedDoc(editSelectedDoc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const getNexDoc = () => {
    const updatedDocs = docs.filter((__, ii) => ii !== deleteDocIndex);
    setDocs(updatedDocs);
    const nextIndex = findIndexOfFirstIncompleteDoc(updatedDocs);
    if (nextIndex < 0 && updatedDocs.length > 0) {
      // Go to the first document if no unfinished doc
      setSelectedIndex(0);
    } else {
      // Go to the next unfinished doc
      setSelectedIndex(nextIndex);
    }
  }

  const handleUpdate = (doc) => {
    const newDocs = [...docs];
    newDocs[selectedIndex] = {
      ...selectedDoc,
      ...doc,
      title: selectedDoc.file.filename,
      completed: true,
    };
    setDocs(newDocs);
    if (nextQueuedDocIndex !== -1) {
      if (nextQueuedDocIndex === -99) {
        // triggered from close modal button
        setDocs(newDocs);
      } else {
        setSelectedIndex(nextQueuedDocIndex);
      }
      setNextQueuedDocIndex(-1);
    } else {
      const nextDocIndex = findIndexOfFirstIncompleteDoc(newDocs);
      if (nextDocIndex === -1) {
        setDocs(newDocs);
      } else {
        setSelectedIndex(nextDocIndex);
        setEditFileIndex(-1);
      }
    }
  };

  return (
    <Dialog
      fullScreen
      hideBackdrop={true}
      open={uploadedDocumentDialogOpen}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
      >
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <CardHeader title='Upload Documents' />
          <IconButton
            aria-label='close'
            data-testid='close-modal'
            onClick={() => {
              setOpenCloseConfirmation(true);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          className={classes.taggingCnt}
        >
          <Grid
            item
            xs={12}
            md={4}
            className={classes.tableCnt}
          >
            {/* Left Pannel */}
            <LeftPannelNav
              docs={docs}
              setHasChanged={setHasChanged}
              setDocs={setDocs}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              onDocSelectChange={(index: number) => {
                if (!hasChanged) {
                  setSelectedIndex(index);
                  setSelectedDoc(docs[index])
                } else {
                  setNextQueuedDocIndex(index);
                }
              }}
              onSubmit={handleUpdate}
              selectedDoc={selectedDoc}
              setSelectedDoc={setSelectedDoc}
              deleteFile={deleteFile}
              addFiles={addFiles}
              onFilesAdded={onFilesAdded}
              validDocFormats={validDocFormats}
              editFileIndex={editFileIndex}
              setFileCompletedStateByIndex={setFileCompletedStateByIndex}
              setUploadedDocumentDialogOpen={setUploadedDocumentDialogOpen}
              setIsEdit={setIsEdit}
              showDeleteConfirmation={(index: number) => {
                setDeleteDocIndex(index);
                setOpenDialog(true);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
          >
            {
              // selectedDoc && (
              <RightPannel
                docs={docs}
                setDocs={setDocs}
                addFiles={addFiles}
                uploadedDocumentDialogOpen={uploadedDocumentDialogOpen}
                setFileCompletedStateByIndex={setFileCompletedStateByIndex}
                setUploadedDocumentDialogOpen={setUploadedDocumentDialogOpen}
                setEditFileIndex={setEditFileIndex}
                editFileIndex={editFileIndex}
                selectedDoc={selectedDoc}
                setSelectedDoc={setSelectedDoc}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
                onSubmit={handleUpdate}
                setHasChanged={setHasChanged}
              />
            }
          </Grid>
          {
            openDialog &&
              <DeleteConfirmationDialog
                index={deleteDocIndex}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog}
                deleteFile={deleteFile}
                getNexDoc={getNexDoc}
              />
          }
          {
            openCloseConfirmation &&
              <CloseConfirmationDialog
                openDialog={openCloseConfirmation}
                setOpenDialog={setOpenCloseConfirmation}
                onCloseConfirmation={() => {
                  const completedDocs = docs.filter((doc) => doc.completed)
                  setDocs(completedDocs);
                  setUploadedDocumentDialogOpen(false);
                }}
              />
          }
        </Grid>
      </DialogContent>
    </Dialog>
  );
}


export default UploadedDocumentDialog;
