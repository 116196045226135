import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Slide,
  Snackbar,
  //Divider,
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  AddCircle as AddCircleIcon,
  Close as CloseIcon
} from '@material-ui/icons';
import DocumentService, {
  UploadDoc,
  validExtensions,
  InvalidDoc
} from './../../../../service/document';
import { useApolloClient } from '@apollo/client';
import { useAxios } from './../../../../hooks/useAxios';
import UploadedDocumentDialog from './uploaded-document-dialog';
import useStyles from './use-styles';
import AddDocumentsProps from './add-documents-props';
import DragDropService from './../../../../service/drag-drop';
import AcceptedDocumentsTable from './AcceptedDocumentsTable';
import { getAcceptedDocs } from 'notarize-now/src/utils/getAcceptedDocs';
import DeleteConfirmationDialog from './DeleteConfirmation';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';


function Alert(props: AlertProps) {
  return (
    <MuiAlert
      elevation={6}
      variant='filled'
      {...props}
    />);
}

const AddDocuments: React.FC<AddDocumentsProps> = ({
  setActiveNextButton,
  documentList,
  setDocumentList,
  validDocFormats,
  uploadDocOnAdded
}) => {
  const { primaryColor } = useCustomColors();
  const axios = useAxios();
  const apollo = useApolloClient();
  // track index of the file selected from the doc list
  const [editFileIndex, setEditFileIndex] = useState(-1);
  const [deleteFileIndex, setDeleteFileIndex] = useState(-1);

  // tracks opened or closed state of the review document dialog
  const [uploadedDocumentDialogOpen, setUploadedDocumentDialogOpen] = useState(false);
  // define trackers for dragging occurring in drag and drop areas
  const [firstDocsDrag, setFirstDocsDrag] = useState(false);
  const [addMoreDocsDrag, setAddMoreDocsDrag] = useState(false);

  // define counters to track depth of files dragged into UI children
  const [firstDocsDragDepthCounter, setFirstDocsDragDepthCounter] = useState(0);
  const [addMoreDocsDragDepthCounter, setAddMoreDocsDragDepthCounter] = useState(0);

  // define references for click to add files
  const inputFirstDocsFileRef = useRef<HTMLInputElement>(null);
  const inputAddMoreDocsFileRef = useRef<HTMLInputElement>(null);

  // define references for drag and drop areas
  const firstDocsDropRef = useRef<HTMLDivElement>(null);
  const addMoreDocsDropRef = useRef<HTMLDivElement>(null);

  const handleOnComplete = () => setActiveNextButton(true);
  const handleOnIncomplete = () => setActiveNextButton(false);

  const [isEdit, setIsEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarErrorMessage, setsnackBarErrorMessage] = useState("")


  // mark file as completed / incomplete
  const setFileCompletedStateByIndex = (value: boolean, updateIndex: number) => {
    documentList[updateIndex].completed = value;
    setDocumentList(documentList);
  };

  // select a file to edit and open dialog
  const openUploadedDocumentDialog = (selectedFileIndex: number) => {
    setEditFileIndex(selectedFileIndex);
    setUploadedDocumentDialogOpen(true);
  };

  // select a file to delete
  const openDeleteConfirmationDialog = (selectedFileIndex: number) => {
    setDeleteFileIndex(selectedFileIndex);
    setOpenDialog(true);
  };

  // delete file from server and client
  const deleteFile = (delIndex: number) => {

    const { nextIndex, nextFileList } = DocumentService.deleteFile(apollo, delIndex, editFileIndex, documentList);
    setEditFileIndex(nextIndex);
    // if(nextIndex === -1)
    if (getAcceptedDocs(documentList).length === documentList.length) {
      setUploadedDocumentDialogOpen(false);
    }
    setDocumentList(nextFileList);
  }



  const CHECK_UPLOADS_INTERVAL = 1000;


  useEffect(() => {
    // is specified in props, upload documents on added
    if (uploadDocOnAdded) {
      const checkUploadsInterval = setInterval(() => {
        DocumentService.attemptNextUpload(
          axios,
          documentList,
          setDocumentList
        )
      }, CHECK_UPLOADS_INTERVAL);
      return () => clearInterval(checkUploadsInterval);

    } else {
      return () => { };
    }
  });

  const errorMessage = (errObj: InvalidDoc) => {
    // make sure when creating new errors that the errorsOptions keys match the checkRecordForError keys on.../src/service/document.ts

    const errorOptions = {
      validExtensions: `Invalid format. Accepted formats are ${Object.keys(validExtensions).join(', ')}`,
      validFileSize: `You cannot upload a document higher than 60MB.`
    }


    setsnackBarErrorMessage(Object.keys(errorOptions).find(key => errObj.name === key) ? errorOptions[errObj.name] : "Error Please Try again.")

    setOpenSnackbar(true);

  }

  // appends passed file list to local document list and opens dialog
  // with first of passed list selected and triggers upload process
  const addFiles = (fileList: UploadDoc[], invalidFilesAttempted: boolean | InvalidDoc) => {
    if (fileList.length > 0) {
      const selectedFileIndex = documentList.length;
      setDocumentList([
        ...documentList,
        ...fileList,
      ]);
      openUploadedDocumentDialog(selectedFileIndex);
    }
    if (invalidFilesAttempted) {
      errorMessage(invalidFilesAttempted as InvalidDoc);
    }
  };


  // handles files added from selected and dropped file eventsa
  const onFilesAdded = (event: Event | any) => {
    const {
      fileList,
      invalidFiles
    } = DocumentService.handleAddFilesEvent(event);
    addFiles(fileList, invalidFiles);
  };


  // setup bootstrap and teardown listeners for the drag and drop files methods
  useEffect(() => {
    const onFileDrop = (event: Event) => {
      const {
        fileList,
        invalidFiles
      } = DocumentService.handleAddFilesEvent(event);
      addFiles(fileList, invalidFiles);
    };
    const trackFirstDocsDrop = DragDropService.setupDropZone({
      ref: firstDocsDropRef,
      dragDepthCounter: firstDocsDragDepthCounter,
      setDragDepthCounter: setFirstDocsDragDepthCounter,
      setDrag: setFirstDocsDrag,
      onFileDrop
    });
    const trackAddMoreDocsDrop = DragDropService.setupDropZone({
      ref: addMoreDocsDropRef,
      dragDepthCounter: addMoreDocsDragDepthCounter,
      setDragDepthCounter: setAddMoreDocsDragDepthCounter,
      setDrag: setAddMoreDocsDrag,
      onFileDrop
    });

    return () => {
      DragDropService.tearDownDropZone({
        ref: firstDocsDropRef,
        trackHandlers: trackFirstDocsDrop
      });
      DragDropService.tearDownDropZone({
        ref: addMoreDocsDropRef,
        trackHandlers: trackAddMoreDocsDrop
      });

    }
  });

  const updateNextButton = () => {
    if (documentList && documentList.length > 0) {
      handleOnComplete();
    }
    else {
      handleOnIncomplete();
    }
  };
  const classes = useStyles();
  useEffect(() => {
    updateNextButton();
  });


  // prompt to add files when no files have been added and drag drop is occurring
  const emptyListDropTargetView = () =>
    <>
      <Grid
        item
        md={2}
        xs={3}
      >
        <div style={{ paddingTop: '10px', marginTop: '14px' }}>
          <img src="/assets/fileUploadIcon.svg" />
        </div>
      </Grid>
      <Grid
        item
        md={7}
        xs={3}
        className={classes.emptyListDetails}
      >
        <h3>
          Drop Here
        </h3>
        <p>
          Upload the full document as a PDF file
        </p>
        <p>
          (not just the signature page)
        </p>
      </Grid>
    </>

  // prompt to add files when no files have been added and no drag drop is occurring
  const emptyListView = () =>
    <>
      <Grid
        item
        md={2}
        xs={3}
      >
      </Grid>
      <div style={{ paddingTop: '10px', marginTop: '14px' }}><img src="/assets/fileUploadIcon.svg" /></div>
      <Grid
        item
        md={7}
        xs={3}
        className={classes.emptyListDetails}
      >
        <h3>
          Drop or add documents here to get started
        </h3>
        <p>
          Upload the full document as a PDF file
        </p>
        <p>
          (not just the signature page)
        </p>
        <input
          multiple
          ref={inputFirstDocsFileRef}
          style={{ display: 'none' }}
          type='file'
          accept={validDocFormats}
          onChange={onFilesAdded}
        />
        <Button
          color='primary'
          variant='contained'
          type='submit'
          data-testid='submit-button'
          className={classes.btn}
          style={{
            backgroundColor: primaryColor
          }}
          onClick={(_event) => {
            if (inputFirstDocsFileRef.current) {

              inputFirstDocsFileRef.current.value = null;
              inputFirstDocsFileRef.current.click();
            }
          }}
        >
          Add A Document
        </Button>
      </Grid>
    </>

  // prompt to add files when no files have been added
  const emptyListViewPanel = () =>
    <Grid
      container
      spacing={2}
      ref={firstDocsDropRef}
      className={classes.emptyListGrid}
    >
      {firstDocsDrag ? emptyListDropTargetView() : emptyListView()}
    </Grid>

  // header section of UX for files added view
  // const populatedListViewHeader = () =>
  //   <div className={classes.populatedListHeaderGrid}>
  //     <div className={classes.populatedListHeaderTitle}>
  //       Title
  //     </div>
  //     <div className={classes.populatedListHeaderActions}>
  //       Actions
  //     </div>
  //     <div className={classes.clearAll}></div>
  //     <Divider />
  //   </div>

  // returns icon to indicate upload progress
  // const listItemStatusIcon = (aFile: UploadDoc) =>
  // aFile.status === 'pending' ? (
  //   <div className={classes.statusIcon}>
  //     <strong>
  //       PENDING
  //     </strong>
  //     &nbsp;&nbsp;
  //     <span>
  //       { aFile.file.title }
  //     </span>
  //   </div>
  // ) : (
  //   aFile.status === 'uploading' ? (
  //     <div className={classes.statusIcon}>
  //       <strong>
  //         UPLOADING
  //       </strong>
  //       &nbsp;&nbsp;
  //       <span>
  //         { aFile.file.title }
  //       </span>
  //     </div>
  //   ) : (
  //     aFile.status === 'error' ? (
  //       <div className={classes.statusIcon}>
  //         <strong>
  //           Error:
  //           {' '}
  //           { aFile.serverMessage }
  //         </strong>
  //         &nbsp;&nbsp;
  //         <span>
  //           { aFile.file.title }
  //         </span>
  //       </div>
  //     ) :
  // (
  //   <div className={classes.fileIcon}>
  //     { aFile.file.title }
  //   </div>
  // )

  // a file line listed in UX for files added view
  const populatedListItem = (_aFile: UploadDoc, index: number) => (
    <div key={index}>
      <Grid
        container
        spacing={2}
        className={classes.populatedListItemLine}
      >
        <Grid
          item
          md={6}
          xs={2}
        >
          {/* { listItemStatusIcon(aFile) } */}
          {/* <AcceptedDocumentsTable docs={aFile}/> */}
        </Grid>
        <Grid
          item
          md={6}
          xs={2}
          style={{
            whiteSpace: 'nowrap'
          }}
        >
          <div
            onClick={(_event: any) => { openUploadedDocumentDialog(index) }}
            className={classes.editFileIcon}
          >
            <EditIcon
              color='primary'
              style={{ color: primaryColor }}
            />
          </div>
          <div
            onClick={(_event: any) => { deleteFile(index) }}
            className={classes.deleteFileIcon}
          >
            <DeleteIcon
              color='primary'
              style={{ color: primaryColor }}
            />
            <span
              className={classes.actionBtnText}
            >
              Delete
            </span>
          </div>
          <div className={classes.clearAll}></div>
        </Grid>
      </Grid>

    </div>
  );

  // add files section of UX with files already uploaded
  const uploadMoreDocumentsPrompt = () =>
    <Grid
      container
      spacing={2}
      className={classes.uploadMoreDocumentsGrid}
      ref={addMoreDocsDropRef}
      onClick={(_event) => {
        if (inputAddMoreDocsFileRef.current) {
          inputAddMoreDocsFileRef.current.click();
        }
      }}
    >
      <Grid
        item
        md={4}
        xs={2}
      >
        <input
          multiple
          ref={inputAddMoreDocsFileRef}
          style={{ display: 'none' }}
          type='file'
          accept={validDocFormats}
          onChange={onFilesAdded}
        />
        <div>
          <AddCircleIcon
            color='primary'
            style={{ color: primaryColor }}
            className={classes.addIcon}
          />
        </div>
      </Grid>
      <Grid
        item
        md={6}
        xs={2}
      >
        <h2 className={classes.addMoreFilesCopy}>
          {addMoreDocsDrag ? 'Drop Here' : 'Add more documents'}
        </h2>
      </Grid>
    </Grid>

  // files added view
  const populatedListView = () =>
    <div
      className={classes.populatedListPadding}
    >
      <Grid
        container
        spacing={2}
        className={classes.populatedListGrid}
      >
        {/* { populatedListViewHeader() } */}
        <AcceptedDocumentsTable
          docs={documentList}
          actionCallback={(actionType, index) => {
            if (actionType === 'Edit') {
              setIsEdit(true);
              openUploadedDocumentDialog(index);
            } else if (actionType === 'Delete') {
              openDeleteConfirmationDialog(index);
              // deleteFile(index);
            }
          }}
        />
        {/* { documentList.map(populatedListItem) } */}
      </Grid>
      <Grid
        container
        spacing={2}
        className={classes.addMoreDocGrid}
      >
        {uploadMoreDocumentsPrompt()}
      </Grid>
    </div>

  //set transition for SnackBar
  const TransitionLeft = (props) => {
    return <Slide {...props} direction="left" />;
  }
  return (
    <>
      {documentList && documentList.length > 0 &&
        <UploadedDocumentDialog
          docs={documentList}
          setDocs={(updatedDocs) => {
            setDocumentList(updatedDocs)
          }}
          initialSelectedIndex={editFileIndex}
          setFileCompletedStateByIndex={setFileCompletedStateByIndex}
          setUploadedDocumentDialogOpen={setUploadedDocumentDialogOpen}
          deleteFile={deleteFile}
          addFiles={addFiles}
          onFilesAdded={onFilesAdded}
          setEditFileIndex={setEditFileIndex}
          editFileIndex={editFileIndex}
          validDocFormats={validDocFormats}
          uploadedDocumentDialogOpen={uploadedDocumentDialogOpen}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />}
      {documentList && documentList.length > 0 ? populatedListView() : emptyListViewPanel()}
      {
        openDialog &&
        <DeleteConfirmationDialog
          index={deleteFileIndex}
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          deleteFile={deleteFile}
        />
      }
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={TransitionLeft}
        onClose={() => setOpenSnackbar(false)}
      >
        <Box
          p='2rem'
        >
          <Alert icon={false} severity='error'>{snackBarErrorMessage}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackbar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>

          </Alert>
        </Box>


      </Snackbar>
    </>
  );
};
export default AddDocuments;
