import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  DialogContentText,
} from '@material-ui/core';
import AddSignerProps from './add-signer-props';
import addStartValues from './add-start-values';
import AddSignerDialog from './add-signer-dialog';
import EditSignerDialog from './edit-signer-dialog';
import AddWitnessDialog from './add-witness-dialog';
import EditWitnessDialog from './edit-witness-dialog';
import ParticipantCard from './participantCard';
import signerSchema from '../../../../utils/validation/signerSchema';
import useStyles from './use-styles';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';

const MAX_SIGNERS = 10;

const AddSigner: React.FC<AddSignerProps> = ({
  setActiveNextButton,
  showPrompt,
  signerList,
  setSignerList,
  className,
}) => {
  const handleOnComplete = () => setActiveNextButton(true);
  const handleOnIncomplete = () => setActiveNextButton(true);
  const updateNextButton = (nextSignerList: any[]) => {
    const foundSigner = nextSignerList.some((aSigner) => aSigner.type === 'Signer');
    foundSigner ? handleOnComplete() : handleOnIncomplete();
  };

  const handleAddOnSubmit = (
    values: any,
    type: string,
    setDialogOpen: (val: boolean) => void
  ) => {
    const nextSignerList = [
      ...signerList,
      {
        ...values,
        type,
      }
    ];
    updateNextButton(nextSignerList);
    setSignerList(nextSignerList);
    setDialogOpen(false);
  };

  const handleEditOnSubmit = (
    values: any,
    index: number,
    type: string,
    setDialogOpen: (val: boolean) => void
  ) => {
    const nextSignerList = [
      ...signerList
    ];

    nextSignerList[index] = values;
    updateNextButton(nextSignerList);
    setSignerList(nextSignerList);
    setDialogOpen(false);
  };
  const handleAddSignerOnSubmit = (values: any) => handleAddOnSubmit(values, 'Signer', setAddSignerDialogOpen);
  const handleAddWitnessOnSubmit = (values: any) => handleAddOnSubmit(values, 'Witness', setAddWitnessDialogOpen);

  const handleEditSignerOnSubmit = (values: any) => handleEditOnSubmit(values, selectedParticipantIndex, 'Signer', setEditSignerDialogOpen);
  const handleEditWitnessOnSubmit = (values: any) => handleEditOnSubmit(values, selectedParticipantIndex, 'Witness', setEditWitnessDialogOpen);

  const removeParticipantByIndex = (deleteIndex: number) => {
    const nextSignerList = signerList.filter((item, index) => index !== deleteIndex);
    updateNextButton(nextSignerList);
    setSignerList(nextSignerList);
  };

  const classes = useStyles();
  const { primaryColor, secondaryColor } = useCustomColors();
  const [selectedParticipantIndex, setSelectedParticipantIndex] = useState<number>();
  const [selectedParticipantData, setSelectedParticipantData] = useState(addStartValues);
  const [addSignerDialogOpen, setAddSignerDialogOpen] = useState(showPrompt);
  const [editSignerDialogOpen, setEditSignerDialogOpen] = useState(false);
  const [addWitnessDialogOpen, setAddWitnessDialogOpen] = useState(false);
  const [editWitnessDialogOpen, setEditWitnessDialogOpen] = useState(false);
  const [removeParticipantConfirmDialogOpen, setRemoveParticipantConfirmDialogOpen] = useState(false);

  useEffect(() => {
    setAddSignerDialogOpen(showPrompt);
  }, [showPrompt])

  useEffect(() => {
    updateNextButton(signerList);
  })

  const onRemove = (deleteIndex: number) => {
    setSelectedParticipantIndex(deleteIndex);
    setRemoveParticipantConfirmDialogOpen(true);
  };

  const onEdit = (editIndex: number, participantData: any) => {
    setSelectedParticipantIndex(editIndex);
    setSelectedParticipantData(participantData);
    if (participantData.type === 'Witness') {
      setEditWitnessDialogOpen(true);
    }
    else if (participantData.type === 'Signer' || participantData.type === 'Primary Signer') {
      setEditSignerDialogOpen(true);
    }
    //console.log(participantData);
    //setSelectedParticipantIndex(editIndex);
    //setRemoveParticipantConfirmDialogOpen(true);
  };

  const removeDialog = () => (
    <Dialog
      open={removeParticipantConfirmDialogOpen}
      fullWidth={true}
    >
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
      >
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Typography className={classes.dialogTitleContent}>Remove this participant?</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogDescription}>
        <DialogContentText
          id='alert-dialog-description'
          color='textPrimary'
        >
          Warning! Are you sure you want to remove this participant?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button
          autoFocus
          onClick={() => {
            removeParticipantByIndex(selectedParticipantIndex);
            setRemoveParticipantConfirmDialogOpen(false);
          }}
          color='primary'
          variant='contained'
          data-testid='confirm-delete-button'
        >
          Yes, Remove
        </Button>
        <Button
          onClick={() => setRemoveParticipantConfirmDialogOpen(false)}
          color='primary'
          variant='contained'
          data-testid='cancel-delete-button'
        >
          No, Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )

  const signerItems = (item: any, index: number) => (
    <Grid
      item
      md={3}
      sm={6}
      xs={12}
      key={index}
      className={classes.signerBox}
    >
      <ParticipantCard
        item={item}
        index={index}
        onRemove={onRemove}
        onEdit={onEdit}
      />
    </Grid>
  );

  const viewControls = () => (
    <Box className={classes.controlPanel}>
      <Button
        variant='contained'
        style={{
          backgroundColor: primaryColor
        }}
        color='primary'
        type='button'
        disabled={(signerList.length < MAX_SIGNERS ? false : true)}
        className={classes.dialogOpenButton}
        onClick={(event) => { setAddSignerDialogOpen(true) }}
      >
        Add Signer
      </Button>
      <Button
        variant='contained'
        style={{
          backgroundColor: primaryColor
        }}
        color='primary'
        type='button'
        disabled={(signerList.length < MAX_SIGNERS ? false : true)}
        className={classes.dialogOpenButton}
        onClick={(event) => { setAddWitnessDialogOpen(true) }}
      >
        Add Witness
      </Button>
    </Box>
  );

  const viewPanel = () => (
    <>
      <h2
        className={classes.panelHeader}
        style={{ color: secondaryColor }}
      >
        Add Signers & Witnesses
      </h2>
      <p className={classes.panelCopy}>
        All additional signers and witnesses are required to be present at the time of the session.  Provide their name, phone number, and email for them to receive their unique PIN for the session.
      </p>

      {viewControls()}

      <Grid
        container
        spacing={3}
        className={classes.signerBoxContainer}
      >
        {signerList.map((aSigner: any, index: number) => signerItems(aSigner, index))}

      </Grid>
    </>
  );

  return (
    <>
      <AddSignerDialog
        className={className}
        isOpenState={addSignerDialogOpen}
        setOpenState={setAddSignerDialogOpen}
        iniValues={addStartValues}
        valSchema={signerSchema}
        handleOnSubmit={handleAddSignerOnSubmit}
      />
      <EditSignerDialog
        className={className}
        isOpenState={editSignerDialogOpen}
        setOpenState={setEditSignerDialogOpen}
        iniValues={selectedParticipantData}
        valSchema={signerSchema}
        handleOnSubmit={handleEditSignerOnSubmit}
      />
      <AddWitnessDialog
        className={className}
        isOpenState={addWitnessDialogOpen}
        setOpenState={setAddWitnessDialogOpen}
        iniValues={addStartValues}
        valSchema={signerSchema}
        handleOnSubmit={handleAddWitnessOnSubmit}
      />
      <EditWitnessDialog
        className={className}
        isOpenState={editWitnessDialogOpen}
        setOpenState={setEditWitnessDialogOpen}
        iniValues={selectedParticipantData}
        valSchema={signerSchema}
        handleOnSubmit={handleEditWitnessOnSubmit}
      />
      {removeDialog()}
      {viewPanel()}
    </>
  );

};
export default AddSigner;
