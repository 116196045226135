import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Container, createStyles, makeStyles, createTheme, ThemeProvider } from '@material-ui/core';
import SignerJourney, { SubmitResult } from './features/signerJourney';
import { useAxios } from './hooks/useAxios';
import { NOTARIZATION_START_URL, getNotarizationDocsWithIdUrl } from './service/urls';
import DocumentService from './service/document';


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: '#F4F6F8',
      minHeight: '100vh'
    },
    appBar: {
      backgroundColor: '#fff',
      alignItems: 'flex-start',
      boxShadow: 'none'
    },
    logoContainer: {
      width: '400px',
      minHeight: 64,
      overflow: 'hidden',
    },
    logo: {
      height: 'auto',
      maxWidth: '100%',
      display: 'block',
    },
    stepper: {
      width: '100%',
      backgroundColor: 'transparent'
    }
  })
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#ED5F42',
    },
    secondary: {
      main: '#507F91',
    },
  },
});




function App(): React.ReactElement {
  const axios = useAxios();
  const classes = useStyles();  
  const [activeStep, setActiveStep] = useState<number>(0);
  const handleBack = () => setActiveStep((currentStep) => currentStep - 1);
  const handleNext = () => setActiveStep((currentStep) => currentStep + 1);

  // create session & send white labelled welcome emails 
  const submitOrderPayload = async (orderPayload) => {
    const result: { data: { id: string }} = await axios.post(
      NOTARIZATION_START_URL,
      orderPayload
    );
    const sessionId = result.data.id;
    return sessionId;
  };

  // save documents
  const submitDocIdsToOrder = async (sessionId, gcsRefIdList) => {
    const url = getNotarizationDocsWithIdUrl(sessionId);
    await axios.post(url, { gcsFileIds: gcsRefIdList });
  };

  // submit all information to server
  const handleSubmit = async (orderPayload, documentList): Promise<SubmitResult> => {
    // create order
    const sessionId = await submitOrderPayload(orderPayload);

    // submit documents to server
    const gcsRefIdList = await DocumentService.uploadAllDocuments(axios, documentList, sessionId);

    // save docs to order
    await submitDocIdsToOrder(sessionId, gcsRefIdList);

    return {
      sessionId,
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component='main'
        maxWidth={false}
        disableGutters
        className={classes.root}
      >
        <Container>
          <SignerJourney
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            handleSubmit={handleSubmit}
          />
        </Container>
      </Container>
    </ThemeProvider>
  );
}

export default App;
