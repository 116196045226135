import { FormikTouched } from 'formik';
import {
  Grid,
  TextField,
} from '@material-ui/core';
type validFieldNames = "firstName" | "middleName" | "lastName" | "email";

type TextFieldDetails = {
  name: validFieldNames,
  required: boolean,
  label: string;
  dataTestId: string;
};

const getAField = ({
  fieldDetails,
  classes,
  customClasses,
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  index
}: {
  fieldDetails: TextFieldDetails,
  classes: any,
  customClasses: any,
  handleBlur: (e: React.FocusEvent<any>) => void,
  handleChange: (e: React.ChangeEvent<any>) => void,
  values: {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  },
  touched: FormikTouched<{
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }>,
  errors: any,
  index: number
}) =>
  <Grid
    item
    md={11}
    xs={12}
    className={classes.textFieldContainer}
    key={index}
  >
    <TextField
      fullWidth
      required={fieldDetails.required}
      variant='filled'
      name={fieldDetails.name}
      label={fieldDetails.label}
      data-testid={fieldDetails.dataTestId}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values[fieldDetails.name]}
      error={Boolean(touched[fieldDetails.name] && errors[fieldDetails.name])}
      helperText={touched[fieldDetails.name] && errors[fieldDetails.name]}
      className={customClasses.textField}
      color='secondary'
    />
  </Grid>

export default getAField;
