import { useCallback } from 'react';

function parseQueryString(search) {

  // Use location.search to access query string instead
  const qs = search.replace('?', '');

  const items = qs.split('&');

  // Consider using reduce to create the data mapping
  return items.reduce((data, item) => {

    const [rawKey, rawValue] = item.split('=');
    const key = decodeURIComponent(rawKey);
    const value = decodeURIComponent(rawValue);

    // Sometimes a query string can have multiple values
    // for the same key, so to factor that case in, you
    // could collect an array of values for the same key
    if (data[key] !== undefined) {

      // If the value for this key was not previously an
      // array, update it
      if (!Array.isArray(data[key])) {
        data[key] = [data[key]]
      }

      data[key].push(value)
    }
    else {

      data[key] = value
    }

    return data

  }, {})
}
export function useQuery() {

  const location = window.location;

  const { search } = location;
  const parse = useCallback(() => {
    const q = parseQueryString(search);
    return {
      ...q,
    }
  }, [search])

  return parse();
}
