/* eslint-disable react-hooks/rules-of-hooks */
import { FC, } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Description as DescriptionIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import SimpleTable from './SimpleTable';
import { IColumnDef } from './SimpleTable';
import { UploadDoc } from 'notarize-now/src/service/document';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';

export interface AcceptedDocumentsTableProps {
  docs: UploadDoc[];
  actionCallback?: (actionType: string, index: number) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width:'100%',
    '& .MuiTableCell-head': {
      textAlign: 'center',
      verticalAlign : 'middle'
    },
    '& .MuiTableBody-root .MuiTableRow-root:last-child td':{
      borderBottom: 0
    }
  },
}));

const documentTitle: IColumnDef<UploadDoc> = {
  title: 'Title',
  render: (ns, _callBack, _index) => {
    const dTitle = ns.file.title;
    return (
      <Typography
        title={dTitle}
        style={{ fontSize:'0.875rem' , padding: '8px' }}
      >
        <DescriptionIcon style={{ marginRight: '8px', }} />
        {_.truncate(dTitle, {
          'length': 45,
        })}
      </Typography>
    );
  },
};
const documentActions: IColumnDef<UploadDoc> = {
  title: 'Actions',
  render: (ns, callBack, index) => (
    <div
      style={{ display:'flex', justifyContent:'flex-end', marginRight:'20px',
      }}
    >
      <span
        onClick={callBack?.bind(null, 'Edit', ns, index)}
        style={{ cursor: 'pointer', float: 'right', marginRight: '25px' }}
      >
        <EditIcon
          color='primary'
          style={{ color: useCustomColors().primaryColor }}
        />
        <Typography style={{ fontSize:'0.7rem' }}>Edit</Typography>
      </span>
      <span
        onClick={callBack?.bind(null, 'Delete', ns, index)}
        style={{ cursor: 'pointer', float: 'right', textAlign: 'center' }}
      >
        <DeleteIcon
          color='primary'
          style={{ color: useCustomColors().primaryColor }}
        />
        <Typography style={{ fontSize:'0.7rem' }}>Delete</Typography>
      </span>
    </div>
  ),
};

const AcceptedDocumentsTable: FC<AcceptedDocumentsTableProps> = ({
  docs,
  actionCallback,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SimpleTable
        rowData={docs}
        columnDefs={AcceptedDocumentsColumns()}
        onClick={(type: string, _data: UploadDoc, index: number) => {
          if(actionCallback) {
            actionCallback(type, index);
          }
        }}
      />
    </div>
  );
};

export const AcceptedDocumentsColumns = (): Array<IColumnDef<UploadDoc>> => [
  documentTitle,
  documentActions,
];

export default AcceptedDocumentsTable;
