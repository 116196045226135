import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppThunk } from '../store';
import { Session } from '@enotarylog/core/types/session';
import { RootState } from '../store';
import { GET_SESSION } from '@enotarylog/gql/session';

interface SessionState {
  session: Session | Record<string, never>;
  loading: boolean;
}

export const initialState: SessionState = {
  session: {},
  loading: false,
};

export const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setLoading: (
      state: SessionState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    setSession: (
      state: SessionState,
      action: PayloadAction<Session>
    ) => {
      state.session = action.payload;
    },
  },
});

export const getSession =
  (id: string): AppThunk =>
    async (dispatch, __, { apollo }) => {
      dispatch(slice.actions.setLoading(true));
      const { data = {} } = await apollo.query({
        query: GET_SESSION,
        variables: {
          id,
        },
      });
      const { getSession: sessionData } = data;
      dispatch(slice.actions.setSession(sessionData));
      dispatch(slice.actions.setLoading(false));
    };

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.session;

export default slice;