import { gql } from '@apollo/client';

export const GET_SESSION_PARTICIPANT = gql`
  query getSessionParticipant($id: String!) {
  getSessionParticipant(id:$id) {
    id
    participantId
    status
    credAnalysisType
  }
}
`;