import { useQuery } from 'notarize-now/src/hooks/useQuery';
import isAColor from '../utils/validation/isAColor';

// Don't forget to URL Encode the hex color value: # = %23
// Valid:   %234455AA
// Invalid: #4455AA
export function useCustomColors() {
  let { primary, secondary, page_background } = useQuery();

  if (!isAColor(primary)) {
    primary = undefined;
  }
  if (!isAColor(secondary)) {
    secondary = undefined;
  }

  if (!isAColor(page_background)) {
    page_background = undefined;
  }

  return {
    primaryColor: primary,
    secondaryColor: secondary,
    background: page_background
  };
}
