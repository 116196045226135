import { Stepper, Step, StepLabel, makeStyles, createStyles } from '@material-ui/core';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';
import CustomStepConnector from './CustomStepConnector';
import CustomStepIcon from './CustomStepIcon';

export interface NotarizeNowStepperProps {
  activeStep: number;
}

const useStyles = makeStyles(() => createStyles({
  stepper: {
    width: '100%',
    backgroundColor: 'transparent'
  },
}));

const steps = [
  { label: 'Equipment Check' },
  { label: 'Add Participants' },
  { label: 'Document Upload' },
  { label: 'Summary' },
  { label: 'Verify Email' },
  { label: 'Continue', arrowIcon: true },
]

const NotarizeNowStepper: React.FC<NotarizeNowStepperProps> = ({ activeStep }) => {
  const { primaryColor } = useCustomColors();
  const useCustomStyles = makeStyles(() => createStyles({
    step: {
      '& .MuiStepIcon-active': {
        color: primaryColor,
      },
      '& .MuiStepIcon-completed': {
        color: primaryColor,
      },
    },
  }));

  const classes = useStyles();
  const customClasses = useCustomStyles();
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      className={classes.stepper}
      connector={<CustomStepConnector />}
      orientation='horizontal'
    >
      {steps.map((step) => (
        <Step
          key={step.label}
          className={customClasses.step}
        >
          <StepLabel StepIconComponent={step.arrowIcon ? CustomStepIcon : undefined}>
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default NotarizeNowStepper;
