import React, { useState, useRef } from 'react';
import {
  IconButton,
  MenuItem,
  ListItemText,
  Menu,
  Tooltip
} from '@material-ui/core';
import useStyles from './use-styles';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

interface ParticipantCardProps {
  item: any;
  index: number;
  onRemove:(index: number) => void;
  onEdit: (index: number, data: any) => void;
};
  

const ParticipantCard: React.FC<ParticipantCardProps> = ({ 
  item,
  index,
  onRemove,
  onEdit
}) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const moreRef = useRef<any>(null);
  const classes = useStyles();

  const handleMenuOpen = (): void => {
    setOpenMenu(true);
  };

  const handleMenuClose = (): void => {
    setOpenMenu(false);
  };


  return (
    <div className={classes.innerSignerBox}>
      { 
        /* eslint-disable */
            item.type === 'Primary Signer' ? 
            <div className={classes.itemToolbar}>
            <Tooltip title='More options'>
              <IconButton
                onClick={handleMenuOpen}
                ref={moreRef}
              >
                <MoreVertIcon/>
              </IconButton>
            </Tooltip>

            <Menu
            open={openMenu}
            onClose={handleMenuClose}
            anchorEl={moreRef.current}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
              <MenuItem>
                <ListItemText primary='Edit' onClick={() => {
                  onEdit(index ,item);
                  setOpenMenu(false);
                  }}/>
              </MenuItem>
            </Menu>
          </div> : 
            <div className={classes.itemToolbar}>
              <Tooltip title='More options'>
                <IconButton
                  onClick={handleMenuOpen}
                  ref={moreRef}
                >
                  <MoreVertIcon/>
                </IconButton>
              </Tooltip>

              <Menu
              open={openMenu}
              onClose={handleMenuClose}
              anchorEl={moreRef.current}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
                <MenuItem>
                  <ListItemText primary='Edit' onClick={() => {
                    onEdit(index ,item);
                    setOpenMenu(false);
                    }}/>
                </MenuItem>
                <MenuItem>
                  <ListItemText primary='Remove' onClick={() => {
                    onRemove(index)
                    setOpenMenu(false);
                  }} />
                </MenuItem>
              </Menu>
            </div>
        }
        <div>
          <div className={classes.signerType}>
            { item.type === 'Primary Signer' ? 'You' : item.type }
          </div>
          <div className={item.type === 'Primary Signer' ? classes.primarySignerDot : ( item.type === 'Signer' ? classes.signerDot : classes.witnessDot )}>
            { `${item.firstName.charAt(0)}${item.lastName.charAt(0)}` }
          </div>
          <div className={classes.signerName}>
            { `${item.firstName} ${item.middleName} ${item.lastName}` }
          </div>
          <div className={classes.signerEmail}>
            {item.email}
          </div>
      </div>
    </div>
  )
}

export default ParticipantCard;