import React from 'react';
import {
  Button,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import { TransactionSummaryInfoGrid, TransactionSummaryInfoGridProps } from './TransactionSummaryInfoGrid';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';

interface TransactionSummaryDialogProps extends TransactionSummaryInfoGridProps {
  isOpen: boolean;
  handleClose: () => void;
};

const TransactionSummaryDialog: React.FC<TransactionSummaryDialogProps> = ({
  isOpen,
  handleClose,
  tableHeaders,
  tableData,
  boldFirstColumn,
  isLeftAlign
}) => {
  const { primaryColor } = useCustomColors();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
    >
      <TransactionSummaryInfoGrid
        tableHeaders={tableHeaders}
        tableData={tableData}
        boldFirstColumn={boldFirstColumn}
        isLeftAlign={isLeftAlign}
      />
      <DialogActions>
        <Button
          data-testid='information-dialogue-close-button'
          onClick={handleClose}
          color='primary'
          style={{
            backgroundColor: primaryColor
          }}
          variant='contained'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default TransactionSummaryDialog;

