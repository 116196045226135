import { validFieldNames } from './text-field-details';
const textFieldList = [
  {
    name:  'firstName' as validFieldNames,
    label: 'First name',
    required: true,
    dataTestId: 'first-name-field',
  },
  {
    name: 'middleName' as validFieldNames,
    label: 'Middle name',
    required: false,
    dataTestId: 'middle-name-field'
  },
  {
    name: 'lastName' as validFieldNames,
    label: 'Last name',
    required: true,
    dataTestId: 'last-name-field'
  },
  {
    name: 'email' as validFieldNames,
    label: 'Email',
    required: true,
    dataTestId: 'email-field'
  },
];

export default textFieldList;