import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { FC } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';

interface TransactionSummaryInfoGridProps {
  tableHeaders?: Array<string>
  tableData: Array<Array<string>>,
  boldFirstColumn?: boolean,
  isLeftAlign?: boolean,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme) => ({
  infoGridStyles:  {
    '& .MuiTableCell-head': {
    //   color: theme.palette.secondary.main
    },
    '& .MuiTablePagination-root': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .MuiIconButton-label': {
      color: '#187082',
      backgroundColor: '#E4EFF1',
      borderRadius: '5px',
    },
  },
  dialogTitle: {
    backgroundColor: '#F4F6F8',
    '& .MuiTypography-subtitle2': {
      fontWeight: 600,
      letterSpacing: '0.5px',
      fontSize: '0.8rem',
    }
  },
  tableCell: {
    '& .MuiTypography-body1': {
      fontSize: '0.8rem',
      letterSpacing: '0.5px'
    }
  }
}));

const TransactionSummaryInfoGrid: FC<TransactionSummaryInfoGridProps> = ({ tableData, tableHeaders = [], boldFirstColumn = false, isLeftAlign = false }: TransactionSummaryInfoGridProps) => {
  const { infoGridStyles, dialogTitle, tableCell } = useStyles();
  return (
    <TableContainer
      component={Paper}
      className={infoGridStyles}
      data-testid='info-grid'
    >
      <Table
        aria-label='simple table'
      >
        {
          tableHeaders.length > 0 &&
            <TableHead className={dialogTitle}>
              <TableRow key='info-grid-headers'>
                {
                  _.map(tableHeaders, (header, headerCellIdx) => (
                    <TableCell
                      align={isLeftAlign ? 'left' : 'center'}
                      key={headerCellIdx}
                    >
                      <Typography variant='subtitle2'>{header}</Typography>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
        }
        <TableBody>
          {
            _.map(tableData, (row, idx) => (
              <TableRow key={idx}>
                {
                  _.map(row, (cell, rowIdx) => (
                    <TableCell
                      className={tableCell}
                      align={isLeftAlign ? 'left' : 'center'}
                      key={rowIdx}
                    >
                      <Typography>
                        {cell}
                      </Typography>
                    </TableCell>
                  ))
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )}

export { TransactionSummaryInfoGrid };
export type { TransactionSummaryInfoGridProps };
