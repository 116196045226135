/* eslint-disable max-lines */
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Theme,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  TextField,
  Checkbox,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  ArrowUpward as UpIcon,
  ArrowDownward as DownIcon,
  AddCircle as AddCircleIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';
import PerfectScrollbar from 'dashboard/src/components/PerfectScrollbar';
import { ManageOrderContext } from 'dashboard/src/contexts/ManageOrderContext';
import { UploadDoc } from 'notarize-now/src/service/document';
import { getAcceptedDocs } from 'notarize-now/src/utils/getAcceptedDocs';
import { findIndexOfFirstIncompleteDoc } from 'notarize-now/src/utils/findFirstIncompleteDoc';
import { BACKGROUND } from 'notarize-now/src/constants';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';

export interface LeftPannelNavProps {
  docs: UploadDoc[];
  setDocs(docs: UploadDoc[]): void;
  selectedIndex: number;
  setSelectedIndex(index: number);
  onDocSelectChange(index: number): void;
  showDeleteConfirmation?(index: number): void;
  selectedDoc?: UploadDoc;
  setSelectedDoc?(updatedDoc: any);
  onSubmit?(updatedDoc: UploadDoc);
  setHasChanged(hasChanged: boolean): void;
  validDocFormats: string;
  deleteFile: (delIndex: number) => void;
  addFiles: (fileList: UploadDoc[], invalid: boolean) => void;
  onFilesAdded: (event: Event | any) => void;
  editFileIndex: number;
  setUploadedDocumentDialogOpen: (open: boolean) => void;
  setFileCompletedStateByIndex: (value: boolean, updateIndex: number) => void;
  setIsEdit(isEdit: boolean): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  sideAddMoreFilesCopy: {
    paddingTop: '0px',
  },
  sideAddMoreFilesCopyContainer: {
    width: '210px',
  },
  addIcon: {
    marginRight: theme.spacing(2),
    fontSize: '2rem',
    width: '180px',
    height: '40px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    cursor: 'pointer',
    float: 'left',
    marginTop: '6px',
  },
  uploadMoreDocumentsSideGrid: {
    width: '100%',
    // marginLeft: 'auto',
    marginRight: 'auto',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: '1px',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
    marginBottom: '5px',
    marginTop: '5px',
    cursor:'pointer'
  },
  actionBtn: {
    position: "relative",
    fontSize: '.6rem',
    marginRight: '5px',
    width: '25px',
    minWidth: '25px',
    padding: 0,
    textTransform: 'none',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
    '&:hover': {
      backgroundColor: 'inherit',
    }
  },
  actionBtnText: {
    position: "absolute",
    bottom: theme.spacing(-2),
  },
  fileDetailsWrapper: {
    padding: theme.spacing(2, 0, 2, 0),
  },
  fileDetailsCnt: {
    paddingBottom: theme.spacing(1),
  },
  styledTable:{
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: BACKGROUND
    }
  }
}));

const LeftPannelNav: FC<LeftPannelNavProps> = ({
  docs,
  setDocs,
  selectedIndex,
  setSelectedIndex,
  onDocSelectChange,
  selectedDoc,
  setSelectedDoc,
  setHasChanged,
  editFileIndex,
  validDocFormats,
  onFilesAdded,
  setUploadedDocumentDialogOpen,
  setFileCompletedStateByIndex,
  onSubmit,
  setIsEdit,
  showDeleteConfirmation,
}) => {
  const { primaryColor } = useCustomColors();
  const classes = useStyles();
  const [sessionType, setSessionType] = useState('');
  const { setHasUnsavedChanges } = useContext(ManageOrderContext);
  const inputAddMoreDocsSidePromptFileRef = useRef<HTMLInputElement>(null);
  const addMoreDocsSidePromptDropRef = useRef<HTMLDivElement>(null);
  const [addMoreDocsSidePromptDrag, setAddMoreDocsSidePromptDrag] = useState(false);

  useEffect(() => {
    firstSelectedType('');
    setSelectedIndex(editFileIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionType]);

  // move a file in the arr, preserving selected index
  const moveArrItem = async (from: number, to: number) => {
    const dupe = [...docs];
    if (to >= 0 && to < docs.length) {
      const item = dupe.splice(from, 1)[0];
      dupe.splice(to, 0, item);
    }
    setDocs(dupe);
    const newSelectedIndex = _.findIndex(
      dupe,
      (doc) => doc.id === docs[selectedIndex].id
    );
    if (newSelectedIndex > -1 && selectedIndex !== newSelectedIndex) {
      setSelectedIndex(newSelectedIndex);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    moveArrItem(oldIndex, newIndex);
  };

  const confirmSelectedDocument = () => {
    setFileCompletedStateByIndex(true, editFileIndex);
    (getAcceptedDocs(docs).length === docs.length) && setUploadedDocumentDialogOpen(false);
  };

  const SortableItem = SortableElement(({ file, fileIndex }) => (
    <TableRow
      className={classes.styledTable}
      data-testid= 'docs-list-table-row'
      style={{ zIndex: 99999999 }}
      selected={selectedIndex === fileIndex}
      onClick={() => {
        if (selectedIndex !== fileIndex) {
          onDocSelectChange(fileIndex);
        }
      }}
      title={file.file.title}
    >
      <TableCell
        component='th'
        scope='row'
      >
        {_.truncate(file.file.title, {
          length: 20,
        })}
      </TableCell>
      <TableCell
        align='center'
        padding='checkbox'
      >
        <Checkbox
          color='primary'
          style={{
            color: (file.completed && selectedIndex !== fileIndex) ? primaryColor : undefined
          }}
          data-testid = 'submit-checkbox'
          disabled={(selectedIndex !== fileIndex && !file.completed || file.file.title === '')}
          onChange={async () => {
            if(selectedIndex === fileIndex && getAcceptedDocs(docs).length !== docs.length){
              setHasChanged(false);
              onSubmit({
                ...docs[selectedIndex],
                completed: true,
              });
              confirmSelectedDocument();
              setIsEdit(false);
              setHasUnsavedChanges(true);
            } else if (getAcceptedDocs(docs).length === docs.length){
              confirmSelectedDocument();
            }
          }}
          checked={file.completed && selectedIndex !== fileIndex}
        />
      </TableCell>
      <TableCell align='center'>
        <Button
          data-testid='up-button'
          size='small'
          className={classes.actionBtn}
          onClick={(event) => {
            event.stopPropagation();
            moveArrItem(fileIndex, fileIndex - 1);
          }}
        >
          <UpIcon
            color='primary'
            style={{ color: primaryColor }}
          />
        </Button>
        <Button
          size='small'
          data-testid='down-button'
          className={classes.actionBtn}
          onClick={(event) => {
            event.stopPropagation();
            moveArrItem(fileIndex, fileIndex + 1);
          }}
        >
          <DownIcon
            color='primary'
            style={{ color: primaryColor }}
          />
        </Button>
        <Button
          size='small'
          className={classes.actionBtn}
          data-testid = 'delete-button'
          onClick={() => {
            showDeleteConfirmation(fileIndex);
          }}
        >
          <DeleteIcon
            color='primary'
            style={{ color: primaryColor }}
          />
          <span
            className={classes.actionBtnText}
          >
            Delete
          </span>
        </Button>
      </TableCell>
    </TableRow>
  ));

  const SortableListContainer = SortableContainer(({ items }) => (
    <Table
      aria-label='simple table'
      // style={{ width:'120%' }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Loaded Documents</TableCell>
          <TableCell align='center'>Completed</TableCell>
          <TableCell align='center'>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((file, i) =>
          !file.id ? null : (
            <SortableItem
              index={i}
              key={file.id}
              file={file}
              fileIndex={i}
            />
          )
        )}
      </TableBody>
    </Table>
  ));

  const setEditFileTitle = (value: string, index: number) => {
    setSelectedDoc({ ...selectedDoc, file: { ...selectedDoc.file, title: value } });
    docs[index].file.title = value;
    setDocs(docs)
  };

  const firstSelectedType = useCallback((val: any) => {
    setSessionType(val);
  }, []);

  return (
    <>
      {/* Left Pannel */}
      <Grid
        container
        className={classes.fileDetailsWrapper}
      >
        <div style={{ width: '100% ' }}>
          <Grid
            item
            xs={12}
            className={classes.fileDetailsCnt}
          >
            <TextField
              disabled={!selectedDoc}
              fullWidth
              variant='filled'
              size='small'
              label='Title'
              required
              data-testid='title-field'
              error={!selectedDoc || !selectedDoc.file.title}
              helperText={(!selectedDoc || !selectedDoc.file.title) && 'Title is required field'}
              value={selectedDoc && selectedDoc.file.title || ''}
              onChange={(e) => {setEditFileTitle(e.target.value, selectedIndex)}}
              // onChange={(e) =>
              //   setSelectedDoc({ ...selectedDoc, file: { ...selectedDoc.file, title: e.target.value } })}
              color='secondary'
            />
          </Grid>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <SortableListContainer
              items={docs}
              onSortEnd={onSortEnd}
              useDragHandle={false}
              lockAxis='y'
              distance={1}
            />
          </PerfectScrollbar>
        </div>
      </Grid>

      <Grid
        container
        spacing={2}
        className={classes.uploadMoreDocumentsSideGrid}
        ref={addMoreDocsSidePromptDropRef}
        onClick={(event) => {
          if(inputAddMoreDocsSidePromptFileRef.current)
          {
            inputAddMoreDocsSidePromptFileRef.current.click();
          }
        }}
      >
        <Grid
          item
          md={4}
          xs={2}
        >
          <input
            multiple
            ref={inputAddMoreDocsSidePromptFileRef}
            style={{ display: 'none' }}
            type='file'
            accept={validDocFormats}
            onChange={onFilesAdded}
          />
          <div>
            <AddCircleIcon
              color='primary'
              style={{ color: primaryColor }}
              className={classes.addIcon}
            />

          </div>
        </Grid>
        <Grid
          item
          md={8}
          xs={2}
        >
          <div className={classes.sideAddMoreFilesCopyContainer}>
            <h3 className={classes.sideAddMoreFilesCopy}>
              { addMoreDocsSidePromptDrag ? 'Drop Here' : 'Add another document' }
            </h3>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default LeftPannelNav;
