import { Box, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useEvent } from 'react-use';
import EquipmentCheck from './pages/equipmentCheck/EquipmentCheck';
import NotarizeNowStepper from './pages/components/NotarizeNowStepper';
import AddPrimarySigner from './pages/addPrimarySigner/AddPrimarySigner';
import AddDocuments from './pages/addDocuments/AddDocuments';
import AddSigner from './pages/addSigner/addSigner';
import TransactionSummary from './pages/transactionSummary/TransactionSummary';
import VerifyEmail from './pages/verifyEmail/verifyEmail';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';

const PRIMARY_SIGNER_TYPE = 'Primary Signer';
const SIGNER_TYPE = 'signer';
const WITNESS_TYPE = 'witness';
export type SubmitResult = {
  sessionId: string;
};

export interface SignerJourneyProps {
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  handleSubmit: (submitPayload, gcsRefIdList) => Promise<SubmitResult>;
}
const SignerJourney: React.FC<SignerJourneyProps> = ({
  activeStep,
  handleBack,
  handleNext,
  handleSubmit,
}) => {
  const { primaryColor, secondaryColor, background } = useCustomColors();
  const validDocFormats = 'pdf, tiff, png, jpeg, docx, doc';
  const [wv, setWv] = useState(null);
  const [activeNextButton, setActiveNextButton] = useState(false);
  const [contactingServer, setContactingServer] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [primarySignerAdded] = useState(false);
  const [displayAddPrimaryDialog, setDisplayAddPrimaryDialog] = useState(false);
  const [displayAddSignerDialog, setDisplayAddSignerDialog] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const defaultSignerList: any[] = [];
  const [signerList, setSignerList] = useState(defaultSignerList);
  const setPrimarySigner = (values: any) => {
    const nextSignerList = [
      ...signerList,
      {
        ...values,
        type: PRIMARY_SIGNER_TYPE,
      },
    ];
    setSignerList(nextSignerList);
    setShowNextButton(true);
    setActiveNextButton(true);
  };

  const colors = [];
  if (primaryColor) colors.push(`primary=${encodeURIComponent(primaryColor)}`);
  if (secondaryColor) colors.push(`secondary=${encodeURIComponent(secondaryColor)}`);
  if (background) colors.push(`background=${encodeURIComponent(background)}`);

  const equipmentCheckUrl = `${process.env.NX_NOTARY_ROOM_APP_URL}/notarization/equipment-check?mock=true&customBackground=%23F4F6F8${colors.length ? '&' + colors.join('&') : ''}`;

  useEvent('message', ({ data, source }) => {
    if (data === 'equipment-check-passed') {
      internalHandleNext();
    }
  });

  const updateSessionId = (nextSessionId) => {
    setSessionId(nextSessionId);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('nsId', nextSessionId);
    if (window.history.pushState) {
      const nextUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        searchParams.toString();
      window.history.pushState({ path: nextUrl }, '', nextUrl);
    } else {
      window.location.search = searchParams.toString();
    }
  };

  const getSubmitPayload = () => {
    const primarySigner = signerList.find(
      (aSigner) => aSigner.type === PRIMARY_SIGNER_TYPE
    );
    if (!primarySigner) {
      throw new Error('Primary signer not found');
    }
    const urlParams = new URLSearchParams(window.location.search);
    return {
      organizationId: urlParams.get('organizationId'),
      firstName: primarySigner.firstName,
      middleName:
        primarySigner.middleName !== '' ? primarySigner.middleName : undefined,
      lastName: primarySigner.lastName,
      email: primarySigner.email,
      notarizationType: 'undetermined',
      participants: signerList
        .filter((aSigner) => aSigner.type !== PRIMARY_SIGNER_TYPE)
        .map((aSigner) => ({
          firstName: aSigner.firstName,
          middleName:
            aSigner.middleName !== '' ? aSigner.middleName : undefined,
          lastName: aSigner.lastName,
          email: aSigner.email,
          type: aSigner.type.toLowerCase(),
        })),
      witnesses: signerList.filter(
        (aSigner) => aSigner.type.toLowerCase() === WITNESS_TYPE
      ).length,
    };
  };

  const defaultDocumentList: any[] = [];
  const [documentList, setDocumentList] = useState(defaultDocumentList);
  // disable skip button on step 2
  const skipButtonVisible = !(activeStep === 1) && activeStep !== 4;
  const submitButtonVisible = activeStep === 3;

  const getActivePanel = () => {
    const props = { setActiveNextButton };
    switch (activeStep) {
      case 1:
        return (
          <AddSigner
            setShowNextButton={setShowNextButton}
            onSubmit={setShowNextButton}
            showPrompt={displayAddSignerDialog}
            setShowPrompt={setDisplayAddSignerDialog}
            signerList={signerList}
            setSignerList={setSignerList}
            {...props}
          />
        );
      case 2:
        return (
          <AddDocuments
            documentList={documentList}
            setDocumentList={setDocumentList}
            setActiveNextButton={setActiveNextButton}
            validDocFormats={validDocFormats}
            setWv={setWv}
            wv={wv}
            uploadDocOnAdded={false}
          />
        );
      case 3:
        return (
          <TransactionSummary
            documentList={documentList}
            signerList={signerList}
            setActiveNextButton={setActiveNextButton}
          />
        );
      case 4:
        return (
          <VerifyEmail
            setShowNextButton={setShowNextButton}
            setShowBackButton={setShowBackButton}
            sessionId={sessionId}
          />
        );
      case 0:
      default:
        return (
          <>
            <AddPrimarySigner
              setShowNextButton={setShowNextButton}
              setActiveNextButton={setActiveNextButton}
              onSubmit={setPrimarySigner}
              handleNext={handleNext}
              showPrompt={displayAddPrimaryDialog}
              setShowPrompt={setDisplayAddPrimaryDialog}
              {...props}
            />
            <div className='embed-responsive'>
              <iframe
                title='equipment-check'
                src={equipmentCheckUrl}
                allow='camera; microphone'
                className='embed-responsive-item'
                width='100%'
                height='675px'
                scrolling='no'
                style={{ border: 'none' }}
              />
            </div>
          </>
        );
    }
  };
  const internalHandleNext = () => {
    if (activeStep === 0 && !primarySignerAdded) {
      setDisplayAddPrimaryDialog(true);
    } else {
      if (activeStep > 0) {
        setShowBackButton(true);
      }
      handleNext();
      setActiveNextButton(false);
    }
  };

  const internalHandleBack = () => {
    if (activeStep < 3) {
      setShowBackButton(false);
    }
    handleBack();
  };
  return (
    <>
      <NotarizeNowStepper activeStep={activeStep} />
      <Box
        display='grid'
        gridTemplateRows='auto auto'
        gridTemplateColumns='repeat(11, 100px)'
        // gridTemplateColumns="repeat(auto-fill, 5)"
        // gridTemplateRows="repeat(auto-fill, minmax(20px, 1fr))"
        mt={2}
      >
        <Box
          mt={2}
          gridColumn='10 / span 2'
          justifySelf='end'
        // display="flex"
        // alignItems="center"
        // justifyContent="center"
        >
          {showBackButton ? (
            <Button
              variant='contained'
              color='primary'
              type='button'
              disabled={contactingServer}
              onClick={internalHandleBack}
              style={{
                backgroundColor: primaryColor || '#ED5F42',
                color: '#FFFFFF',
                marginRight: '5px',
              }}
            >
              Back
            </Button>
          ) : (
            ''
          )}

          {submitButtonVisible ? (
            <Button
              variant='contained'
              color='primary'
              type='button'
              disabled={!activeNextButton || contactingServer}
              onClick={async (event) => {
                setContactingServer(true);
                const { sessionId: nextSessionId } = await handleSubmit(
                  getSubmitPayload(),
                  documentList
                );
                updateSessionId(nextSessionId);
                setContactingServer(false);
                handleNext();
              }}
              style={{
                backgroundColor: primaryColor || '#ED5F42',
                color: '#FFFFFF',
                opacity: `${activeNextButton ? 1 : 0.5}`,
              }}
            >
              SUBMIT
            </Button>
          ) : (
            ''
          )}
          {!showNextButton ? (
            skipButtonVisible ? (
              <Button
                variant='contained'
                color='primary'
                type='button'
                onClick={internalHandleNext}
                style={{
                  backgroundColor: primaryColor,
                }}
              >
                Skip
              </Button>
            ) : (
              ''
            )
          ) : !submitButtonVisible ? (
            <Button
              variant='contained'
              color='primary'
              type='button'
              disabled={!activeNextButton}
              onClick={internalHandleNext}
              style={{
                backgroundColor: primaryColor || '#ED5F42',
                color: '#FFFFFF',
                opacity: `${activeNextButton ? 1 : 0.5}`,
              }}
            >
              Next
            </Button>
          ) : (
            ''
          )}
        </Box>

        <Box
          gridColumn='2 / 12'
          // display="flex"
          // alignItems="center"
          // justifyContent="center"
          pt={3}
        >
          {getActivePanel()}
        </Box>
      </Box>
    </>
  );
};

export default SignerJourney;
