import { makeStyles } from '@material-ui/core';
import { BACKGROUND } from 'notarize-now/src/constants';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiCardContent-root': {
      paddingTop: '0px'
    },
    '& .MuiPaper-root .MuiDialog-paper .MuiDialog-paperScrollPaper .MuiDialog-paperWidthSm': {
      maxHeight: '626px !important',
    },
  },
  titleText: {
    paddingBottom: '0px',
  },
  panelHeader: {
    color: '#507F91',
    textAlign: 'center'
  },
  controlPanel: {
    display:'flex',
    justifyContent:'center'
  },
  panelCopy: {
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  header: {
    paddingBottom: '0px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  textFieldContainer: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  textField: {
    color: '507F91'
  },
  btn: {
    backgroundColor: 'ED5F42',
  },
  signerBoxContainer: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
  },
  signerBox :{
    width: '25%'
  },
  innerSignerBox: {
    position:'relative',
    backgroundColor: '#FFFFFF',
    borderStyle: 'solid',
    borderColor: '#F3F5F7',
    borderWidth: '1px',
    minHeight: '100%',
    padding: '2.5rem 0.5rem',
    boxShadow: '0 0 6px rgb(0 0 0 / 17%)',
    borderRadius: '0.5rem',
  },
  signerType: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '1rem'
  },
  signerName: {
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  signerEmail: {
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  dialogTitle : { 
    backgroundColor: BACKGROUND , 
    padding: 0,
  },
  dialogTitleContent:{
    fontSize: '0.9rem',
    fontWeight: 600,
    padding: '20px 20px 20px 50px'
  },
  dialogDescription:{
    paddingLeft: '50px'
  },
  dialogOpenButton: {
    width: '137px',
    margin: '0px 15px 19px;',
    backgroundColor: "#ED5F42",
  },
  itemToolbar: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
  },
  itemCloseBtn: {
    float: 'right',
  },
  itemToolbarEnd: {
    clear: 'both'
  },
  witnessDot: {
    height: '65px',
    width: '65px',
    paddingTop: '22px',
    backgroundColor: '#6890F8',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
    color: '#FFFFFF',
  },
  primarySignerDot: {
    height: '65px',
    width: '65px',
    paddingTop: '22px',
    backgroundColor: '#B8F1A4',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
    color: '#FFFFFF',
  },
  signerDot: {
    height: '65px',
    width: '65px',
    paddingTop: '22px',
    backgroundColor: '#E69BEF',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
    color: '#FFFFFF',
  },
  titleBanner: {
    textAlign: 'center',
    padding: '0px 35px'
  },
}));

export default useStyles;
