import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppThunk } from '../store';
import { SessionParticipant } from '@enotarylog/core/types/sessionParticipant';
import { RootState } from '../store';
import { GET_SESSION_PARTICIPANT } from '@enotarylog/gql/sessionParticipant';

interface SessionParticipantState {
  sessionParticipant: SessionParticipant | Record<string, never>;
  loading: boolean;
}

export const initialState: SessionParticipantState = {
  sessionParticipant: {},
  loading: false,
};

export const slice = createSlice({
  name: 'sessionParticipant',
  initialState,
  reducers: {
    setLoading: (
      state: SessionParticipantState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    setParticipant: (
      state: SessionParticipantState,
      action: PayloadAction<SessionParticipant>
    ) => {
      state.sessionParticipant = action.payload;
    },
  },
});

export const getSessionParticipant =
  (id: string): AppThunk =>
    async (dispatch, __, { apollo }) => {
      dispatch(slice.actions.setLoading(true));
      const { data = {} } = await apollo.query({
        query: GET_SESSION_PARTICIPANT,
        variables: {
          id,
        },
      });
      const { getSessionParticipant: sessionParticipantData  } = data;
      dispatch(slice.actions.setParticipant(sessionParticipantData));
      dispatch(slice.actions.setLoading(false));
    };

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.sessionParticipant;

export default slice;
