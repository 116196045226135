import React, { CSSProperties, useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import {
  colors,
  Box,
  LinearProgress,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    backgroundColor: colors.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: '24px'
  }
}));

export const LoadingScreen: FC<{ style?: CSSProperties }> = ({ style }) => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div
      className={classes.root}
      style={style}
    >
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};
