import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  customCheckboxContainer: {
    display: 'block',
    position: 'relative',
    paddingLeft: '35px',
    marginBottom: '12px',
    cursor: 'pointer',
    fontSize: '22px',
    userSelect: 'none',
  },
  customCheckbox: {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0,
  },
  customCheckboxHelper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '20px',
    width: '20px',
    borderWidth: '1px',
    borderColor: '#000000',
    borderStyle: 'solid',
    backgroundColor: '#EEEEEE',
  },
  customCheckboxHelperChecked: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '20px',
    width: '20px',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: '#ED5F42',
  },
  customCheckboxHelperCheck: {
    display: 'none',
  },
  populatedListPadding: {
    paddingTop: '50px',
  },
  customCheckboxHelperCheckChecked: {
    height: '10px',
    width: '5px',
    borderColor: '#FFFFFF',
    position: 'absolute',
    borderWidth: '0 3px 3px 0',
    borderStyle: 'solid',  
    transform: 'rotate(45deg);',
    left: '7px',
    top: '3px',
  },
  populatedListHeaderGrid: {
    padding: '10px',
    width: '100%',
  },
  populatedListGrid: {
    paddingBottom: '0px',
    backgroundColor: '#FFFFFF',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: '1px',
    borderRadius: '25px',
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  addMoreDocGrid: {
    paddingTop:'22px',
    borderColor: '#000000',
    borderWidth: '1px',
    borderRadius: '25px',
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  emptyListGrid: {
    backgroundColor: '#FFFFFF',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: '1px',
    padding: '8px',
    borderRadius: '25px',
    width: '825px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  emptyListIcon: {
    backgroundImage: 'url(/assets/fileUploadIcon.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    backgroundSize: 'contain',
    marginLeft: '120px',
    marginTop: '10px',
    height: '165px',
    width: '65px',
  },
  emptyListDetails: {
    marginTop: '8px',
    textAlign: 'center',
  },
  btn: {
    backgroundColor: "#ED5F42",
    color: '#FFFFFF',
  },
  sideAddMoreFilesIcon: {
    width: '60px',
    height: '60px',
    backgroundImage: 'url(/assets/addMoreFilesIcon.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    cursor: 'pointer',
    float: 'left',
    marginLeft: '20px',
    marginTop: '15px',
  },
  sideAddMoreFilesCopy: {
    paddingTop: '15px',
  },
  sideAddMoreFilesCopyContainer: {
    width: '210px',
    paddingLeft: '23px',
  },
  uploadedDocumentDialogSidebarDeleteIcon: {
    width: '36px',
    height: '45px',
    backgroundImage: 'url(/assets/dialogDeleteFileIcon.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    cursor: 'pointer',
    float: 'left',
    marginLeft: '40px',
  },
  unselectedUploadedDocumentDialogSidebarLine: {
    backgroundColor: '#FFFFFF',
    paddingTop: '6px',
    height: '65px',
    width: '335px',
  },
  selectedUploadedDocumentDialogSidebarLine: {
    backgroundColor: '#E0E0E0',
    paddingTop: '6px',
    height: '65px',
    width: '335px',
  },
  uploadedDocumentDialogSidebarHeader: {
    width: '334px',
    paddingTop: '15px',
  },
  uploadedDocumentDialogSidebarHeaderTitle: {
    textAlign: 'left',
    paddingLeft: '10px',
    float: 'left',
    fontWeight: 'bold',
    width: '145px',  
  },
  uploadedDocumentDialogSidebarTitle: {
    textAlign: 'left',
    paddingLeft: '10px',
    paddingTop: '12px',
    float: 'left',
    width: '190px',
    cursor: 'pointer',
  },
  uploadedDocumentDialogSidebarHeaderCompleted: {
    textAlign: 'center',
    float: 'left',
    fontWeight: 'bold',
    width: '104px',
  },
  uploadedDocumentDialogSidebarCompleted: {
    float: 'left',
    width: '48px',
    paddingTop: '12px',
  },
  uploadedDocumentDialogSidebarHeaderActions: {
    textAlign: 'center',
    float: 'left',
    fontWeight: 'bold',
    width: '80px',
  },
  uploadedDocumentDialogSidebarActions: {
    float: 'left',
    width: '80px',
  },
  populatedListItemLine: {
    borderBottomStyle: 'solid',
    borderBottomColor: '#fafafa',
    borderBottomWidth: '1px',
    marginTop: '8px',
  },

  populatedListHeaderTitle: {
    color: '#569FAC',
    textAlign: 'left',
    float: 'left',
    width: '580px',
    paddingLeft: '125px',
    paddingRight: '145px',
  },
  populatedListHeaderActions: {
    color: '#569FAC',
    textAlign: 'right',
    float: 'left',
    paddingLeft: '5px',
    paddingRight: '45px',
    width: '150px',
  },
  statusIcon: {
    width: '428px',
    height: '30px',
    paddingLeft: '75px',
    paddingTop: '4px',
    marginLeft: '30px',
  },
  fileIcon: {
    width: '428px',
    height: '30px',
    backgroundImage: 'url(/assets/fileIcon.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    paddingLeft: '75px',
    paddingTop: '4px',
    marginLeft: '30px',
  },
  editFileIcon: {
    width: '28px',
    height: '45px',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    cursor: 'pointer',
    float: 'left',
    marginLeft: '210px',
  },
  deleteFileIcon: {
    width: '36px',
    height: '45px',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    cursor: 'pointer',
    float: 'left',
    marginLeft: '40px',
  },
  actionBtnText: {
    position: "absolute",
    marginLeft: '-26px',
    paddingTop: '26px',
    fontSize: '10px'
  },
  clearAll: {
    clear: 'both',
  },
  addMoreFilesIcon: {
    width: '180px',
    height: '40px',
    backgroundImage: 'url(/assets/addMoreFilesIcon.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    cursor: 'pointer',
    float: 'left',
    marginTop: '6px'
  },
  addMoreFilesCopy: {
    fontSize: '17px'  
  },
  deleteLabel: {
    paddingTop: '30px',
  },
  uploadMoreDocumentsSideGrid: {
    width: '345px',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: '1px',
    borderRadius: '25px',
    backgroundColor: '#FFFFFF',
    marginBottom: '5px',
    marginTop: '5px',
    height: '110px',
  },
  uploadMoreDocumentsGrid: {
    width: '65%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: '1px',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
    marginBottom: '5px',
    marginTop: '5px',
    cursor: 'pointer'
  },
  confirmDocumentBtn: {
    backgroundColor: '#E65D42',
    color: '#FFFFFF',
    position: 'absolute',
    bottom: '0',
    right: '0',
    marginRight: '10px',
    marginBottom: '10px',
  },
  documentTitleTextFieldContainer: {
    width: '335px',
    paddingTop: '10px',
  },
  documentTitleTextField: {
    backgroundColor: '#DEDEDE',
    padding: '4px',
  },
  uploadedDocumentDialogSidebarLeft: {
    float: 'left',
    marginRight: '10px',
    width: '340px',
  },
  uploadedDocumentDialogSelectedRight: {
    float: 'left',
    minWidth: '400px',
    width: '60%',
    height: '735px',
  },
  addIcon: {
    marginRight: '16px',
    fontSize: '2rem',
    width: '180px',
    height: '40px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    cursor: 'pointer',
    float: 'left',
    marginTop: '6px',
  },
}));

export default useStyles;
