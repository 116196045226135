import React, { useEffect } from 'react';
import {
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Theme,
  IconButton,
  Button,
  DialogActions,
  DialogContentText,
  Typography
} from '@material-ui/core';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
import { BACKGROUND } from 'notarize-now/src/constants';
  
interface DeleteConfirmationProps {
  index: number;
  setOpenDialog:(openDialog: boolean) => void;
  openDialog: boolean;
  deleteFile: (delIndex: number) => void;
  getNexDoc?: () => void;
}
  
const useStyles = makeStyles((theme: Theme) => ({
  dialog:{
    width: '660px'
  },
  dialogTitle: {
    padding: 0,
    backgroundColor: BACKGROUND,
  },
  dialogTitleContent:{
    fontSize: '0.9rem',
    fontWeight: 600,
    padding: '20px 20px 20px 50px'
  },
  dialogDescription:{
    paddingLeft: '50px'
  },
  taggingCnt: {
    height: '90vh',
    '& .scrollbar-container': {
      height: 'calc(100% - 440px)',
    },
  },
  tableCnt: {
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& .MuiTableCell-root': {
      padding: theme.spacing(1),
      fontSize: '.75rem',
    }
  },
}));
  
const DeleteConfirmationDialog: React.FC<DeleteConfirmationProps> = ({ index, openDialog, setOpenDialog, deleteFile, getNexDoc }) => {
  const classes = useStyles();
  useEffect(() => {
    setOpenDialog(true);
  },[setOpenDialog]);
  
  return (
    <Dialog
      open={openDialog}
      fullWidth={true}
      maxWidth='sm'
    //   className={classes.dialog}
    >
      <DialogTitle
        disableTypography  
        className={classes.dialogTitle}
      >
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Typography className={classes.dialogTitleContent}>Delete this document?</Typography> 
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogDescription}>
        <DialogContentText
          id='alert-dialog-description'
          color='textPrimary'
        >
          Warning! Deleting this document will remove it from your order.
          <br/>
          Are you sure you want to delete this document?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button
          onClick={async () => {
            getNexDoc && getNexDoc();
            deleteFile(index);
            setOpenDialog(false);
          }}
          color='primary'
          variant='contained'
          autoFocus
        >
          YES, DELETE 
        </Button>
        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
          color='primary'
          variant='contained'
          autoFocus
        >
          NO, CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}
    
  
export default DeleteConfirmationDialog;
  