import React, { useEffect, createContext } from 'react';
import { AxiosInstance } from 'axios';
import axios from 'axios';

const axiosInstance = axios.create();
const AxiosContext = createContext<AxiosInstance>(axiosInstance);

export function AxiosProvider({ children }: any) {
  useEffect(() => {
    const interceptorId = axiosInstance.interceptors.request.use(async (config) => config);
    return () => axiosInstance.interceptors.request.eject(interceptorId);
  });

  return (
    <AxiosContext.Provider value={axiosInstance as AxiosInstance}>
      { children }
    </AxiosContext.Provider>
  );
};

export default AxiosContext;
