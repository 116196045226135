import { FC } from 'react';
import { StepIcon, createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  ArrowRight as ArrowRightIcon
} from 'react-feather';

const useCustomStepIconStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.38)',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '24px',
      width: '24px'
    }
  }));

const CustomStepIcon: FC = () => {
  const classes = useCustomStepIconStyles();
  return (
    <span className={classes.root}>
      <StepIcon
        icon={
          <ArrowRightIcon
            color='#F4F6F8'
            size={15}
          />
        }
      />
    </span>
  );
};

export default CustomStepIcon;
