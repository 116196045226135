import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";
import _ from 'lodash';
import { AppThunk, RootState } from "@enotarylog/redux/store";
import { Organization } from "@enotarylog/core/types/organization";
import { IndexedById } from "@enotarylog/core/types/paginated";
import { User } from "@enotarylog/core/lib/types/user";


interface OrganizationState {
  organizations: IndexedById<Organization>;
  loading: boolean;
  orgsBeingFetched: Record<string, boolean>; // Key is organizationId, value tells you if that organization is currently being fetched. Good for avoiding double-fetching
};

export const initialState: OrganizationState = {
  organizations: {
    byId: {},
    allIds: [],
    total: 0
  },
  loading: false,
  orgsBeingFetched: {},
};

export const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setLoading: (state: OrganizationState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addOrganizations: (state: OrganizationState, action: PayloadAction<{ total: number, organizations: Array<Organization> }>) => {
      const { organizations, total } = action.payload;

      const curr = (state.organizations || {
        byId: {},
        allIds: [],
        total: 0
      }) as IndexedById<Organization>;

      const newById = _.chain(organizations)
        .groupBy('id')
        .mapValues((el) => _.head(el))
        .value();

      curr.byId = {
        ...curr.byId,
        ...newById
      }

      curr.allIds = _.keys(curr.byId);
      curr.total = total;

      state.organizations.byId = curr.byId;
      state.organizations.allIds = curr.allIds;
      state.organizations.total = curr.total;

    },
    addUsers: (state: OrganizationState, action: PayloadAction<{ total: number, orgId: string, users: Array<string> }>) => {
      const { orgId, users } = action.payload;
      state.organizations.byId[orgId] = {
        ...state.organizations.byId[orgId],
        users
      }
    },
    addUser: (state: OrganizationState, action: PayloadAction<{ orgId: string, user: string }>) => {
      const { orgId, user } = action.payload;
      state.organizations.byId[orgId] = {
        ...state.organizations.byId[orgId],
        users: _.uniq([...(state.organizations.byId[orgId]?.users || []), user])
      }
    },
    addOrganization(state, { payload: { organization } }: PayloadAction<{ organization: Organization }>) {
      state.organizations.byId[organization.id] = organization;
      if (!state.organizations.allIds.includes(organization.id)) {
        state.organizations.allIds.push(organization.id);
      }
    },
    removeOrganization(state, { payload: { orgId } }: PayloadAction<{ orgId: string }>) {
      delete state.organizations.byId[orgId]
      state.organizations.allIds = _.filter(state.organizations.allIds, (id) => id !== orgId);
    }
  }
});




/**
 * Gets list of organizations in enl enterprise system
 */
export const getOrganizations = (): AppThunk => async (dispatch, __, { axios }) => {
  dispatch(slice.actions.setLoading(true))
  const { data: { data } } = await axios.get('/api/organizations');

  dispatch(slice.actions.addOrganizations({
    total: data.length,
    organizations: data,
  }))
  dispatch(slice.actions.setLoading(false))
}




export const createOrganization = (payload): AppThunk => async (dispatch, __, { axios }) => {

  dispatch(slice.actions.setLoading(true))
  const { data } = await axios.post('/api/organizations', payload);

  dispatch(slice.actions.addOrganization({
    organization: data
  }))

  dispatch(slice.actions.setLoading(false))
}

export const deleteOrganization = (orgId: string): AppThunk => async (dispatch, __, { axios }) => {

  dispatch(slice.actions.setLoading(true))
  await axios.delete(`/api/organizations/${orgId}`);

  dispatch(slice.actions.removeOrganization({
    orgId
  }))

  dispatch(slice.actions.setLoading(false))
}














export const { addOrganization } = slice.actions;


export const reducer = slice.reducer;
export const selector = (state: RootState) => state.organizations
export default slice;