import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloClient, HttpLink, split, InMemoryCache } from '@apollo/client';
import url from 'url';
import { setContext } from '@apollo/client/link/context';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const createLink = (url: string) => {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    }
  });

  const http = new HttpLink({ uri: `${window.location.origin}${url}` });

  return authLink.concat(http);
}


export const initApollo = (withSubscriptions = true) => {
  const apiPath = process.env.NX_GQL_PATH || '/graphql';

  const link = createLink(apiPath);
  const gqlWsLink = new WebSocketLink(new SubscriptionClient(
    `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${window.location.host}/api/subscriptions`,
    {
      reconnect: true,
      lazy: true,
    },
  ));
  return new ApolloClient({
    ssrMode: false,
    connectToDevTools: true,
    link: withSubscriptions
      ? split(({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      }, gqlWsLink, link)
      : link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {

          },
        },
      },
    }),
  });
};
