import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";
import _ from 'lodash';
import { AppThunk, RootState } from "@enotarylog/redux/store";
import { IndexedById } from "@enotarylog/core/types/paginated";
import { User } from "@enotarylog/core/lib/types/user";

import { slice as orgSlice } from "./organizations";


interface UsersState {
  users: IndexedById<User>;
  loading: boolean;
};

export const initialState: UsersState = {
  users: {
    byId: {},
    allIds: [],
    total: 0
  },
  loading: false,
};

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setLoading: (state: UsersState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addUsers: (state: UsersState, action: PayloadAction<{ total: number, users: Array<User> }>) => {
      const { users, total } = action.payload;

      const curr = (state.users || {
        byId: {},
        allIds: [],
        total: 0
      }) as IndexedById<User>;

      const newById = _.chain(users)
        .groupBy('user_id')
        .mapValues((el) => _.head(el))
        .mapValues((el) => ({ ...el, id: el.user_id }))
        .value();

      curr.byId = {
        ...curr.byId,
        ...newById
      }

      curr.allIds = _.keys(curr.byId);
      curr.total = total;

      state.users.byId = curr.byId;
      state.users.allIds = curr.allIds;
      state.users.total = curr.total;

    },
    addUser(state, { payload: { user } }: PayloadAction<{ user: User }>) {
      if (!user) {
        return;
      }
      state.users.byId[user.user_id] = user;
      if (!state.users.allIds.includes(user.id)) {
        state.users.allIds.push(user.id);
      }
    }
  }
});


/**
 * Gets list of organizations in enl enterprise system
 */
export const getUsers = (orgId: string): AppThunk => async (dispatch, __, { axios }) => {
  dispatch(slice.actions.setLoading(true))
  const { data } = await axios.get(orgId ? `/api/organizations/${orgId}/members` : '/api/users');

  dispatch(slice.actions.addUsers({
    total: data.length,
    users: data,
  }));

  dispatch(orgSlice.actions.addUsers({
    total: data.length,
    orgId: orgId,
    users: _.map(data, 'user_id'),
  }));
  dispatch(slice.actions.setLoading(false))
}

export const getUserByAuthId = (authId: string) : AppThunk => async (dispatch, __, { axios }) => {
  dispatch(slice.actions.setLoading(true))
  const { data } = await axios.get(`/api/user/${authId}`);

  dispatch(slice.actions.addUsers({
    total: data.length,
    users: data,
  }));

  dispatch(slice.actions.setLoading(false))
}


export const createUser = (id: string, payload): AppThunk => async (dispatch, __, { axios }) => {

  dispatch(slice.actions.setLoading(true))
  const { data } = await axios.post<User>(`/api/organizations/${id}/members`, payload);

  dispatch(slice.actions.addUser({
    user: data
  }))

  dispatch(orgSlice.actions.addUser({
    orgId: id,
    user: data.id
  }));


  dispatch(slice.actions.setLoading(false))
}













export const { addUser: addOrganization } = slice.actions;


export const reducer = slice.reducer;
export const selector = (state: RootState) => state.users
export default slice;