import React from 'react';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { BACKGROUND, TABLE_CELL_BTN } from 'notarize-now/src/constants';
import { useCustomColors } from 'notarize-now/src/hooks/useCustomColors';

export interface IColumnDef<T> {
  title: string;
  renderTitle?: React.ReactNode;
  helperText?: string;
  render: (arg: T, callBack?: (type: string, row: T) => void, index?: number) => React.ReactNode;
  width?: number
}

interface ISimpleTableProps<T> {
  rowData: Array<T>;
  columnDefs: Array<IColumnDef<T>>;
  onClick?: (type: string, row: T, index?: number) => void;
  centerCells?: boolean,
  headerBackground? : boolean
}

function SimpleTable<T>(props: ISimpleTableProps<T>) {
  const { secondaryColor } = useCustomColors();

  const useStyles = makeStyles(() => ({
    simpleTable: {
      '& .MuiTableCell':{
        textAlign: 'none'
      },
      '& .MuiTableCell-root':{
        padding: '10px'
      },
      '& .MuiTableCell-head': {
        color: secondaryColor || TABLE_CELL_BTN,
        verticalAlign:'top',
        textAlign: 'none'
      },
      '& .MuiTablePagination-root': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .MuiIconButton-label': {
        color: TABLE_CELL_BTN,
        backgroundColor: BACKGROUND,
        borderRadius: '5px',
      },
    },
    tableHeadBox : {
      textAlign:'right',
      marginRight:'36px',
      paddingTop: '13px',
    },
    titleHeader : {
      textAlign: 'left',
      marginLeft: '30px',
      height: '45px',
      paddingTop: '13px',
    }
  }));

  const { rowData, columnDefs, onClick, headerBackground } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.simpleTable}
    >
      <PerfectScrollbar>
        <Table
          data-testid='simple-table'
        >
          <TableHead
            style={headerBackground ? { background: BACKGROUND }: {}}
          >
            <TableRow>
              {_.map(columnDefs, (cd, i) => (
                <TableCell
                  align='left'
                  key={`${cd.title}-${i}`}
                  style ={{ paddingTop: '10px' }}
                >
                  <Box className= {cd.title === 'Title' ? classes.titleHeader : classes.tableHeadBox}>
                    {cd.renderTitle ? cd.renderTitle : cd.title}
                    <Typography
                      variant='caption'
                      style={{ fontSize: '0.875rem' }}
                    >
                      {cd.helperText}
                    </Typography>
                  </Box>

                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map((row, i) => {
              try {
                return (
                  <TableRow
                    hover
                    key={i}
                  >
                    {_.map(columnDefs, (cd, ii) => (
                      <TableCell
                        //align='right'
                        key={`${i}-${ii}`}
                      >
                        {row !== null ? cd.render(row, onClick, i) : <Skeleton variant='text' /> }
                      </TableCell>
                    ))}
                  </TableRow>
                );
              } catch (ex) {
                console.error(ex);
                return null;
              }
            })}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </div>
  );
}

export default SimpleTable;
