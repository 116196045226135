import React from 'react';

class DragDropService {
  
  static handleDrag(event: Event)
  {
    event.preventDefault();
    event.stopPropagation(); 
  }

  static makeHandleDragIn({
    dragDepthCounter,
    setDragDepthCounter,
    setDrag
  }: {
    dragDepthCounter: number,
    setDragDepthCounter: (count: number) => void,
    setDrag: (drag: boolean) => void
  })
  {
    return (event: Event | any) => {
      event.preventDefault();
      event.stopPropagation();
      setDragDepthCounter(dragDepthCounter + 1);
      if(event.dataTransfer.items && event.dataTransfer.items.length > 0) 
      {
        setDrag(true);
      }
    };
  }

  static makeHandleDragOut({
    dragDepthCounter, 
    setDragDepthCounter,
    setDrag,
  }: {
    dragDepthCounter: number,
    setDragDepthCounter: (drag: number) => void,
    setDrag: (drag: boolean) => void,
  })
  {
    return (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      const nextDepth = dragDepthCounter - 1;
      setDragDepthCounter(nextDepth);
      if(nextDepth < 1)
      {
        setDrag(false);
      }
    };
  }

  static makeHandleDrop({
    setDrag,
    dragDepthCounter,
    setDragDepthCounter,
    onFileDrop,
  }: {
    setDrag: (drag: boolean) => void,
    dragDepthCounter: number,
    setDragDepthCounter: (counter: number) => void,
    onFileDrop: (event: Event) => void,
  })
  {
    return (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      setDrag(false);
      setDragDepthCounter(0);
      onFileDrop(event);
    };
  }

  static setupDropZone({
    ref,
    dragDepthCounter,
    setDragDepthCounter,
    setDrag,
    onFileDrop,
  }: {
    ref: React.RefObject<HTMLDivElement>,
    dragDepthCounter: number,
    setDragDepthCounter: (count: number) => void,
    setDrag: (drag: boolean) => void,
    onFileDrop: (event: Event) => void,
  })
  {
    const handleDragIn = this.makeHandleDragIn({
      dragDepthCounter,
      setDragDepthCounter,
      setDrag,
    });

    const handleDragOut = this.makeHandleDragOut({
      dragDepthCounter,
      setDragDepthCounter,
      setDrag
    });

    const handleDrop = this.makeHandleDrop({
      setDrag,
      dragDepthCounter,
      setDragDepthCounter,
      onFileDrop,
    });

    const dropZone = ref.current;
    dropZone?.addEventListener('dragenter', handleDragIn);
    dropZone?.addEventListener('dragleave', handleDragOut);
    dropZone?.addEventListener('dragover', this.handleDrag);
    dropZone?.addEventListener('drop', handleDrop);

    return {
      handleDragIn,
      handleDragOut,
      handleDrag: this.handleDrag,
      handleDrop,
    };
  }

  static tearDownDropZone({
    ref,
    trackHandlers,
  }: {
    ref: React.RefObject<HTMLDivElement>,
    trackHandlers: {
      handleDragIn: (event: Event) => void,
      handleDragOut: (event: Event) => void,
      handleDrag: (event: Event) => void,
      handleDrop: (event: Event) => void,
    }
  })
  {
    const dropZone = ref.current;
    dropZone?.removeEventListener('dragenter', trackHandlers.handleDragIn);
    dropZone?.removeEventListener('dragleave', trackHandlers.handleDragOut);
    dropZone?.removeEventListener('dragover', trackHandlers.handleDrag);
    dropZone?.removeEventListener('drop', trackHandlers.handleDrop);
  }
}

export default DragDropService;
