import { ReactNode } from 'react';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';
import * as bowser from 'bowser';

export interface ScrollbarProps {
  options: ScrollBarProps['options'];
  children: ReactNode;
}

const isSafari = bowser.getParser(window.navigator.userAgent).getBrowserName() === 'Safari';

export default function Scrollbar({ options, children }: ScrollbarProps) {
  if (isSafari) {
    const style = {
      overflowY: options.suppressScrollY ? 'hidden' as const : 'auto' as const,
      overflowX: options.suppressScrollX ? 'hidden' as const : 'auto' as const,
    };
    return <div style={style}>{children}</div>;
  } else {
    return <PerfectScrollbar options={options}>{children}</PerfectScrollbar>;
  }
}